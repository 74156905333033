export const colors = {
  transparent: 'transparent',
  white: '#ffffff',
  gris: {
    EEEEEE: '#EEEEEE',
    F2F2F2: '#F2F2F2',
    F7F7F7: '#F7F7F7',
    '9A9A9A': '#9A9A9A',
  },

  gradient1: '#0556c2',
  gradient2: '#2290df',

  bg: '#F2F2F2',
  input: '#f1f3f5',
  text: "#101010",
  primary: '#0F357F',
  primaryHover: '#04245a',
  primary2: '#5079c8',
  primaryHover2: '#4069b6',
  secondary: '#F0B841',
  secondaryHover: '#e2ab35',
  icon: '#DE6432',
  iconHover: '#cd5726',
  button: '#D0232A',
  buttonHover: '#bb191f',
}