import React, { useCallback, useEffect, useMemo } from 'react';
// import { useSelector } from 'react-redux';
import sortBy from 'lodash/sortBy';
import { DataTable } from 'mantine-datatable';
import SEO from '../../../components/SEO';
import Loading from '../../../components/Loading';
import { useCustomState } from '../../../hooks/useCustomState';
import Grid from '../components/Grid';
import { getColaboradores } from '../../../api/admin/colaboradores';
// import { hasPermissions } from '../../../router/RequireAuth';

const PAGE_SIZES = [10, 15, 20, 50, 100];

const Colaboradores = () => {
  // const user_store = useSelector(state => state?.admin?.auth?.user?.user || state?.admin?.auth?.user);
  const [state, setState] = useCustomState({
    sending: false,
    loading: true,
    page: 1,
    page_size: PAGE_SIZES[0],
    totalRecords: 0,
    sortStatus: {},
    rowSelection: [],
    colaboradores: [],
  });

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  const loadData = useCallback(async () => {
    const colaboradores = await getColaboradores();

    setState({
      colaboradores: colaboradores?.data,
      totalRecords: (colaboradores?.data)?.length,
      loading: false,
    });
  }, [setState]);

  const onSortStatusChange = useCallback(async (sortStatus) => {
    const data = sortBy(state.colaboradores, sortStatus.columnAccessor);

    setState({
      sortStatus: sortStatus,
      colaboradores: sortStatus.direction === 'desc' ? data.reverse() : data
    });
  }, [state.colaboradores, setState]);

  const { columns, colaboradoresFilter } = useMemo(
    () => {
      const from = (state.page - 1) * state.page_size;
      const to = from + state.page_size;

      return {
        columns: [
          {
            accessor: 'employeeID',
            title: "No de empleado",
            sortable: true,
          },
          {
            accessor: 'name',
            title: "Nombre",
            sortable: true,
          },
          {
            accessor: 'email',
            title: "Correo",
            sortable: true,
          },
          {
            accessor: 'phone',
            title: "Teléfono",
            sortable: true,
          },
          {
            accessor: 'noIMSS',
            title: "IMSS",
            sortable: true,
          },
          {
            accessor: 'department',
            title: "Departamento",
            sortable: true,
          },
        ],
        colaboradoresFilter: (state?.colaboradores)?.slice(from, to)
      }
    },
    [state?.colaboradores, state.page, state.page_size],
  );

  return (
    <>
      <SEO
        title="Colaboradores"
      />
      <Grid>
        <div className='bg-bg h-full pb-4'>
          {state.loading ? (
            <Loading containerClassName="h-full" />
          ) : (
            <>
              <div className='bg-white px-6 lg:px-8 py-6 shadow-sm flex flex-wrap items-center justify-center md:justify-between gap-4'>
                <h1 className='flex-1 text-primary font-startup-semibold text-3xl text-center md:text-left'>Colaboradores</h1>
              </div>
              <div className='px-6 lg:px-8 container_table mt-6'>
                <DataTable
                  withTableBorder
                  withColumnBorders
                  borderRadius="md"
                  striped
                  highlightOnHover
                  horizontalSpacing="xs"
                  verticalSpacing="xs"
                  // height={window.innerHeight - 200}
                  sortStatus={state.sortStatus}
                  onSortStatusChange={onSortStatusChange}
                  recordsPerPageOptions={PAGE_SIZES}
                  recordsPerPageLabel={"Resultados por página"}
                  totalRecords={state.totalRecords || 0}
                  recordsPerPage={state.page_size}
                  page={state.page}
                  onPageChange={page => setState({ page })}
                  onRecordsPerPageChange={page => setState({ page_size: page })}
                  noRecordsText="No se encontraron resultados"
                  records={colaboradoresFilter}
                  columns={columns}
                />
              </div>
            </>
          )}
        </div>
      </Grid>
    </>
  );
};

export default Colaboradores;