import React from 'react';
import { Center, Image } from '@mantine/core';
import SEO from '../../components/SEO';
import Grid from './components/Grid';

const Home = () => {
  return (
    <>
      <SEO
        title="Inicio"
      />
      <Grid classNameInner={"h-full"}>
        <div className='bg-Magnolia h-full'>
          <div className='h-full bg-bg px-6 lg:px-8 py-6'>
            <Center className='flex flex-col gap-10 h-full'>
              <div className='flex'>
                <Image
                  src={"/assets/images/logo.svg"}
                  className="w-full max-w-[200px] select-none pointer-events-none"
                  loading="lazy"
                />
              </div>
              <div className='text-center'>
                <h1 className='font-startup-bold text-primary text-md'>BIENVENIDO A</h1>
                <p className='font-startup-medium text-2xl max-w-[380px]'>La plataforma administrativa de Prosazón</p>
              </div>
            </Center>
          </div>
        </div>
      </Grid>
    </>
  );
};

export default Home;