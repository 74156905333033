import React, {useCallback, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {DatePickerInput} from '@mantine/dates';
import {notifications} from '@mantine/notifications';
import {Box, Button, FileButton, Group, Image, Select, Text, TextInput} from '@mantine/core';
import SEO from '../../../components/SEO';
import {ButtonCustom} from '../../../components/Button';
import {useCustomState} from '../../../hooks/useCustomState';
import Grid from '../components/Grid';
import {isEmpty} from '../../../utils';
import getPreviewImage from '../../../utils/getPreviewImage';
import pathsRouter from '../../../router/pathsRouter';
import {createNotice, getNotices, updateNotice} from "../../../api/admin/notices";
import {dayjs_custom} from "../../../utils/dayjs_custom";
import parseErrorMessage from "../../../utils/parseErrorMessage";
import {colors} from "../../../theme/colors";

const prefix = "avisos";

const ModificarAviso = ({isEditar, isCrear}) => {
    const isDisabled = (!isEditar && !isCrear);
    const navigate = useNavigate();
    const {id} = useParams();
    const types = [
        {
            label: 'Comunicado',
            value: 'comunicado'
        },
        {
            label: 'Evento',
            value: 'evento'
        },
        {
            label: 'Noticia',
            value: 'noticia'
        },
    ]
    const [state, setState] = useCustomState({
        sending: false,
        sending_draft: false,
        title: "",
        type: "",
        validity: undefined,
        imageFile: null,
        imageFilePreview: null,
        isDraft: false,
    });

    const loadData = useCallback(async (_id) => {
        const notices = (_id) ? await getNotices(_id) : null;
        const _notice = (_id) ? notices?.data?.[0] : null;

        const params_edit = _id ? {
            isDraft: _notice?.isDraft,
            title: _notice?.title,
            type: _notice?.type,
            validity: [dayjs_custom(_notice?.validityStart || null)?.toDate(), dayjs_custom(_notice?.validityEnd || null)?.toDate()],
            imageFilePreview: _notice?.image,
        }: {};

        setState({
            loading: false,
            ...params_edit,
        });
    }, [setState]);

    useEffect(() => {
        loadData(id);
    }, [id, loadData]);

    const message = useCallback((title, message) => {
        notifications.show({
            title: title,
            message: message,
        });
    }, []);

    const onCreate = useCallback(async (isDraft = false) => {
        console.log(state.avisos);
        if (isEmpty(state.title)) return message(null, 'El nombre del aviso es obligatorio.');
        if (isEmpty(state.type)) return message(null, 'El tipo de aviso es obligatorio.');

        try {
            setState(isDraft ? {sending_draft: true} : {sending: true});

            const formDataFields = {
                isDraft: isDraft ? (!state.isDraft)?.toString() : "false",
                title: state?.title,
                type: state?.type,
                image: (state.imageFile && state.imageFile),
                validityStart: state?.validity?.[0],
                validityEnd: state?.validity?.[1],
            };

            const _formData = new FormData();

            Object.entries(formDataFields).forEach(([key, value]) => {
                if (value) {
                    if (Array.isArray(value)) {
                        if (["image"].includes(key)) {
                            value.forEach(item => _formData.append(key, item));
                        } else {
                            value.forEach(item => _formData.append(key + '[]', item));
                        }
                    } else {
                        _formData.append(key, value);
                    }
                }
            });

            const response = isCrear ? await createNotice(_formData) : await updateNotice(id, _formData);

            setState(isDraft ? {sending_draft: false} : {sending: false});
            if (response?.status === true) {
                if (isDraft) {
                    message(state.isDraft ? "Aviso publicada" : "Guardado en borradores", `¡El aviso se ha ${state.isDraft ? "publicado" : "guardado en borradores"} exitosamente!`);
                    setState({isDraft: !state.isDraft});
                } else {
                    message(`Aviso ${isCrear ? "creado" : "actualizado"}`, `¡El aviso se ha ${isCrear ? "creado" : "actualizado"} exitosamente!`);
                }
                navigate(`${pathsRouter.pathAdmin}/${prefix}`);
            } else {
                message('Ocurrio un problema', parseErrorMessage(response) || 'Lo sentimos, ocurrió un error con la solicitud. Por favor, inténtalo de nuevo más tarde.');
            }
        } catch (error) {
            setState(isDraft ? {sending_draft: false} : {sending: false});
            message('Ocurrio un problema', parseErrorMessage(error) || 'Lo sentimos, ocurrió un error inesperado. Por favor, inténtalo de nuevo más tarde.');
        }

        // eslint-disable-next-line
    }, [state.title, state.type, state.imageFile, state.validity, setState, navigate, message]);

    return (
        <>
            <SEO
                title={isEditar ? "Editar aviso" : (isCrear ? "Crear nuevo aviso" : "Ver aviso")}
            />
            <Grid>
                <div className='bg-bg h-full pb-4'>
                    <div
                        className='bg-white px-6 lg:px-8 py-6 shadow-sm flex flex-wrap items-center justify-center md:justify-between gap-4'>
                        <h1 className='text-primary font-startup-semibold text-3xl text-center md:text-left'>{isEditar ? "Editar aviso" : (isCrear ? "Crear nuevo aviso" : "Ver aviso")}</h1>
                    </div>
                    <div className='px-6 lg:px-8'>
                        <div
                            className='flex flex-col bg-white mt-10 px-8 py-10 rounded-lg mx-auto md:mx-0 max-w-md w-full'>
                            <div className='flex flex-col gap-5 mb-6'>
                                <TextInput
                                    size="md"
                                    variant='filled'
                                    label="Título"
                                    placeholder="Escribe"
                                    labelProps={{
                                        ff: "Startup-Medium",
                                        fw: 500,
                                        mb: 5,
                                        pl: 15
                                    }}
                                    classNames={{
                                        label: "text-sm",
                                        input: "h-auto py-1 text-sm",
                                    }}
                                    value={state?.title}
                                    onChange={(event) => setState({title: event.currentTarget.value})}
                                    disabled={isDisabled}
                                />
                                <DatePickerInput
                                    allowSingleDateInRange
                                    locale="es"
                                    type="range"
                                    valueFormat="DD MMM YYYY"
                                    label="Vigencia"
                                    variant='filled'
                                    placeholder="Seleccionar"
                                    labelProps={{
                                        ff: "Startup-Medium",
                                        fw: 500,
                                        mb: 5,
                                        pl: 15
                                    }}
                                    classNames={{
                                        root: "text-sm",
                                        monthThead: "text-sm",
                                        label: "text-sm",
                                        input: "h-auto py-2.5 text-sm capitalize",
                                    }}
                                    disabled={isDisabled}
                                    value={state.validity}
                                    onChange={(value) => setState({validity: value})}
                                />
                                <Select
                                    size="md"
                                    variant='filled'
                                    label="Tipo"
                                    placeholder="Selecciona"
                                    data={types}
                                    labelProps={{
                                        ff: "Startup-Medium",
                                        fw: 500,
                                        mb: 5,
                                        pl: 15
                                    }}
                                    classNames={{
                                        label: "text-sm",
                                        input: "h-auto py-1 text-sm",
                                    }}
                                    disabled={isDisabled}
                                    value={state.type}
                                    onChange={(value) => setState({type: value})}
                                />
                                <Box className='relative' radius="md" maw={260}>
                                    <Image
                                        className='bg-gray-100 max-h-[320px]'
                                        radius="md"
                                        fit="contain"
                                        alt="Preview portada"
                                        src={state?.imageFilePreview ? state?.imageFilePreview : null}
                                        fallbackSrc="https://placehold.co/600x300?text=Placeholder"
                                    />
                                    {state?.imageFile && (
                                        <div className='bg-black/40 absolute top-0 left-0 rounded-t-lg w-full'>
                                            <Text
                                                className='text-sm text-white font-startup font-semibold p-2'>{state?.imageFile?.name}</Text>
                                        </div>
                                    )}
                                </Box>
                                {!isDisabled && (
                                    <Group justify="flex-start">
                                        <FileButton
                                            accept="image/png,image/jpeg"
                                            className={`h-auto flex items-center font-startup-medium py-2.5 border-primary bg-primary hover:!bg-primaryHover hover:!border-primaryHover text-xs px-6`}
                                            onChange={file => {
                                                setState({imageFile: file});
                                                getPreviewImage(file, (image) => setState({imageFilePreview: image}));
                                            }}
                                            disabled={isDisabled}
                                        >
                                            {(props) =>
                                                <Button {...props}>{state?.imageFile ? "Cambiar imagen" : "Subir imagen"}</Button>}
                                        </FileButton>
                                    </Group>
                                )}
                            </div>
                            <div className='flex flex-wrap justify-center sm:justify-end items-center gap-3 mt-14'>
                                <ButtonCustom
                                    text={"REGRESAR"}
                                    classNameButton="border-stone-950 bg-stone-950 !text-neutral-200 hover:!bg-stone-900 hover:!border-stone-900 text-xs px-6"
                                    onClick={() => navigate(`${pathsRouter.pathAdmin}/${prefix}`)}
                                />
                                {(isEditar || isCrear) && (
                                    <>
                                        <ButtonCustom
                                            text={state.isDraft ? "PUBLICAR" : "GUARDAR COMO BORRADOR"}
                                            classNameButton="border-primary bg-white hover:!bg-gray-100 hover:!border-primaryHover text-primary text-xs px-6"
                                            loader={{
                                                color: colors.text
                                            }}
                                            onClick={() => onCreate(true)}
                                            loading={state.sending_draft}
                                        />
                                        <ButtonCustom
                                            text={isEditar ? "ACTUALIZAR" : "CREAR"}
                                            classNameButton="border-primary bg-primary hover:!bg-primaryHover hover:!border-primaryHover text-xs px-6"
                                            onClick={() => onCreate(false)}
                                            loading={state.sending}
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Grid>
        </>
    );
};

export default ModificarAviso;