const parseErrorMessage = (error) => {
  try {
    if (error?.response?.status === 404) {
      return "No se encontraron resultados.";
    } else if (error?.response?.data?.errors) {
      const errors = error.response.data.errors;
      for (const key in errors) {
        if (errors.hasOwnProperty(key) && Array.isArray(errors[key]) && errors[key].length > 0) {
          if (key === 'departmentID') {
            return "Debe seleccionar un departamento."
          } else if (key === 'typeID') {
            return "Debe seleccionar un tipo."
          } else if (key === 'name') {
            return "El nombre es obligatorio."
          } else if (key === 'summary') {
            return "La descripción corta es obligatoria."
          } else if (key === 'description') {
            return "La descripción es obligatoria."
          } else if (key === 'price') {
            return "El precio es obligatorio."
          } else if (key === 'validityStart') {
            return "La fecha de vigencia es obligatoria."
          } else if (key === 'validityEnd') {
            return "La fecha de vigencia es obligatoria."
          } else if (key === 'requireTextAnswer') {
            return "La respuesta es obligatoria."
          } else if (key === 'requireImageAnswer') {
            return "La imagen es obligatoria."
          } else if (key === 'requireAudioAnswer') {
            return "El audio es obligatorio."
          } else if (key === 'image') {
            return "La imagen es obligatoria."
          } else if (key === 'imageFile') {
            return "La imagen es obligatoria."
          } else if (key === 'audio') {
            return "El audio es obligatorio."
          } else if (key === 'audioFile') {
            return "El audio es obligatorio."
          } else if (key === 'lastName') {
            return "El apellido es obligatorio."
          } else if (key === 'isDraft') {
            return "La accion es obligatoria."
          } else if (key === 'isDraft') {
            return "La accion es obligatoria."
          } else if (key === 'email') {
            return "El correo electronico es obligatorio."
          } else if (key === 'password') {
            return "La contrasena es obligatoria."
          } else if (key === 'rewardsWallet') {
            return "La saldo de la billetera es obligatoria."
          } else if (key === 'rewardsWallet') {
            return "La saldo de la billetera es obligatoria."
          } else if (key === 'stock') {
            return "La cantidad de inventario es obligatoria."
          } else if (key === 'permissionID') {
            return "El permiso es obligatorio."
          } else if (key === 'firstName') {
            return "El nombre es obligatorio."
          } else if (key === 'answer') {
            return "La respuesta es obligatoria."
          }
          return errors[key][0];
        } else if (errors.hasOwnProperty(key)) {
          return errors[key];
        }
      }
    }

    if (error?.response?.data?.message) {
      return error.response.data.message;
    } else {
      return "Hubo un error al procesar la solicitud. Vuelva a intentarlo más tarde.";
    }
  } catch (error) {
    return null;
  }
};

export default parseErrorMessage;