import React, { memo, useCallback, useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useDisclosure } from '@mantine/hooks';
import { Box, Collapse, Drawer, Image, Stack } from "@mantine/core";
import { ReactComponent as BarsIcon } from '../../../assets/icons/bars-icon.svg';
import { ReactComponent as LogoutIcon } from '../../../assets/icons/menu/logout.svg';
import { ReactComponent as InicioIcon } from '../../../assets/icons/menu/inicio.svg';
import { ReactComponent as UsuariosIcon } from '../../../assets/icons/menu/usuarios.svg';
import { ReactComponent as PermisosIcon } from '../../../assets/icons/menu/permisos.svg';
import { ReactComponent as ColaboresIcon } from '../../../assets/icons/menu/colaboradores.svg';
import { ReactComponent as AccionesIcon } from '../../../assets/icons/menu/acciones.svg';
import { ReactComponent as RecompensasIcon } from '../../../assets/icons/menu/recompensas.svg';
import { ReactComponent as ReportesIcon } from '../../../assets/icons/menu/reportes.svg';
import { ReactComponent as AvisosIcon } from '../../../assets/icons/menu/avisos.svg';
import { ReactComponent as DropdownIcon } from '../../../assets/icons/dropdown-icon.svg';
// import { useCustomState } from "../../../hooks/useCustomState";
import { logout } from "../../../redux/reducers/admin/authSlice";
import { colors } from "../../../theme/colors";
import pathsRouter from "../../../router/pathsRouter";
import { hasPermissions } from "../../../router/RequireAuth";

const LayoutMenuLeft = memo(() => {
  const [opened, { open, close }] = useDisclosure(false);
  const { user: getUser } = useSelector(state => state.admin.auth);
  const user = getUser?.user || getUser;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { pathCurrent, isActiveMenu, classNameIsActiveMenu } = useMemo(() => ({
    pathCurrent: `${pathsRouter.pathAdmin}`,
    isActiveMenu: (location, pathname, contains = false) => ((location?.pathname === pathname || (contains === true && location?.pathname?.indexOf(pathname) !== -1)) ? true : false),
    classNameIsActiveMenu: (location, pathname, contains = false) => ((location?.pathname === pathname || (contains === true && location?.pathname?.indexOf(pathname) !== -1)) ? "!bg-primaryHover font-startup-semibold !text-white cursor-pointer" : "font-startup-medium text-white cursor-pointer"),
  }), []);

  const [openedReportes, handlersReportes] = useDisclosure(isActiveMenu(location, `${pathCurrent}/reportes`, true) ? true : false);

  const cerrarSesion = useCallback(() => {
    dispatch(logout());
    navigate(`${pathsRouter.pathAdmin}/`);
  }, [dispatch, navigate]);

  const MenuNav = useCallback(() => (
    <div className="flex flex-1 flex-col pt-7 space-y-2">
      <div className="flex-1 space-y-3 mb-6">
        <Link to={`${pathCurrent}/`} className={`${classNameIsActiveMenu(location, `${pathCurrent}/`)} flex items-center gap-6 px-4 py-4 rounded-lg bg-transparent hover:bg-primaryHover transition-all`}>
          <InicioIcon fill={isActiveMenu(location, `${pathCurrent}/`) ? colors.white : colors.secondary} height={16} width={16} />
          <span className={`text-inherit transition-all`}>Inicio</span>
        </Link>
        {hasPermissions({ permissions: user?.Permission?.usersPermissions, requiredPermissions: ['leer', 'crear', 'actualizar', 'eliminar'], logicalOperator: 'OR' }) && (
          <Link to={`${pathCurrent}/usuarios`} className={`${classNameIsActiveMenu(location, `${pathCurrent}/usuarios`, true)} flex items-center gap-6 px-4 py-4 rounded-lg bg-transparent hover:bg-primaryHover transition-all`}>
            <UsuariosIcon fill={isActiveMenu(location, `${pathCurrent}/usuarios`, true) ? colors.white : colors.secondary} height={16} width={16} />
            <span className={`text-inherit transition-all`}>Usuarios</span>
          </Link>
        )}
        {hasPermissions({ permissions: user?.Permission?.permissionsPermissions, requiredPermissions: ['leer', 'crear', 'actualizar', 'eliminar'], logicalOperator: 'OR' }) && (
          <Link to={`${pathCurrent}/permisos`} className={`${classNameIsActiveMenu(location, `${pathCurrent}/permisos`, true)} flex items-center gap-6 px-4 py-4 rounded-lg bg-transparent hover:bg-primaryHover transition-all`}>
            <PermisosIcon fill={isActiveMenu(location, `${pathCurrent}/permisos`, true) ? colors.white : colors.secondary} height={16} width={16} />
            <span className={`text-inherit transition-all`}>Permisos</span>
          </Link>
        )}
        {hasPermissions({ permissions: user?.Permission?.collaboratorsPermissions, requiredPermissions: ['leer', 'crear', 'actualizar', 'eliminar'], logicalOperator: 'OR' }) && (
          <Link to={`${pathCurrent}/colaboradores`} className={`${classNameIsActiveMenu(location, `${pathCurrent}/colaboradores`, true)} flex items-center gap-6 px-4 py-4 rounded-lg bg-transparent hover:bg-primaryHover transition-all`}>
            <ColaboresIcon fill={isActiveMenu(location, `${pathCurrent}/colaboradores`) ? colors.white : colors.secondary} height={16} width={16} />
            <span className={`text-inherit transition-all`}>Colaboradores</span>
          </Link>
        )}
        {hasPermissions({ permissions: user?.Permission?.actionsPermissions, requiredPermissions: ['leer', 'crear', 'actualizar', 'eliminar'], logicalOperator: 'OR' }) && (
          <Link to={`${pathCurrent}/acciones`} className={`${classNameIsActiveMenu(location, `${pathCurrent}/acciones`, true)} flex items-center gap-6 px-4 py-4 rounded-lg bg-transparent hover:bg-primaryHover transition-all`}>
            <AccionesIcon fill={isActiveMenu(location, `${pathCurrent}/acciones`, true) ? colors.white : colors.secondary} height={16} width={16} />
            <span className={`text-inherit transition-all`}>Acciones</span>
          </Link>
        )}
        {hasPermissions({ permissions: user?.Permission?.rewardsPermissions, requiredPermissions: ['leer', 'crear', 'actualizar', 'eliminar'], logicalOperator: 'OR' }) && (
          <Link to={`${pathCurrent}/recompensas`} className={`${classNameIsActiveMenu(location, `${pathCurrent}/recompensas`, true)} flex items-center gap-6 px-4 py-4 rounded-lg bg-transparent hover:bg-primaryHover transition-all`}>
            <RecompensasIcon fill={isActiveMenu(location, `${pathCurrent}/recompensas`, true) ? colors.white : colors.secondary} height={16} width={16} />
            <span className={`text-inherit transition-all`}>Recompensas</span>
          </Link>
        )}
        {hasPermissions({ permissions: user?.Permission?.reportsPermissions, requiredPermissions: ['leer', 'actualizar'], logicalOperator: 'OR' }) && (
          <>
            <Box component="button" onClick={handlersReportes.toggle} className={`${classNameIsActiveMenu(location, `${pathCurrent}/reportes`, true)} w-full flex items-center gap-6 px-4 py-4 rounded-lg bg-transparent hover:bg-primaryHover transition-all`}>
              <ReportesIcon fill={isActiveMenu(location, `${pathCurrent}/reportes`, true) ? colors.white : colors.secondary} height={16} width={16} />
              <span className={`flex-1 text-inherit transition-all text-left`}>Reportes</span>
              <DropdownIcon height={24} />
            </Box>
            <Collapse in={openedReportes} transitionDuration={1000} transitionTimingFunction="linear">
              <Stack pl={16} gap={"md"}>
                <Link to={`${pathCurrent}/reportes_participacion`} className={`${classNameIsActiveMenu(location, `${pathCurrent}/reportes_participacion`, true)} flex items-center gap-6 px-4 py-4 rounded-lg bg-transparent hover:bg-primaryHover transition-all`}>
                  <ReportesIcon fill={isActiveMenu(location, `${pathCurrent}/reportes_participacion`, true) ? colors.white : colors.secondary} height={16} width={16} />
                  <span className={`text-inherit transition-all`}>Reporte de participación</span>
                </Link>
                <Link to={`${pathCurrent}/reportes_prosapesos`} className={`${classNameIsActiveMenu(location, `${pathCurrent}/reportes_prosapesos`, true)} flex items-center gap-6 px-4 py-4 rounded-lg bg-transparent hover:bg-primaryHover transition-all`}>
                  <ReportesIcon fill={isActiveMenu(location, `${pathCurrent}/reportes_prosapesos`, true) ? colors.white : colors.secondary} height={16} width={16} />
                  <span className={`text-inherit transition-all`}>Reporte de prosapesos</span>
                </Link>
                <Link to={`${pathCurrent}/reportes_recompensas`} className={`${classNameIsActiveMenu(location, `${pathCurrent}/reportes_recompensas`, true)} flex items-center gap-6 px-4 py-4 rounded-lg bg-transparent hover:bg-primaryHover transition-all`}>
                  <ReportesIcon fill={isActiveMenu(location, `${pathCurrent}/reportes_recompensas`, true) ? colors.white : colors.secondary} height={16} width={16} />
                  <span className={`text-inherit transition-all`}>Reporte de recompensas</span>
                </Link>
              </Stack>
            </Collapse>
          </>
        )}
        {hasPermissions({ permissions: user?.Permission?.noticesPermissions, requiredPermissions: ['leer', 'crear', 'actualizar', 'eliminar'], logicalOperator: 'OR' }) && (
          <Link to={`${pathCurrent}/avisos`} className={`${classNameIsActiveMenu(location, `${pathCurrent}/avisos`, true)} flex items-center gap-6 px-4 py-4 rounded-lg bg-transparent hover:bg-primaryHover transition-all`}>
            <AvisosIcon fill={isActiveMenu(location, `${pathCurrent}/avisos`, true) ? colors.white : colors.secondary} height={16} width={16} />
            <span className={`text-inherit transition-all`}>Avisos</span>
          </Link>
        )}
      </div>

      <div className="border-t border-primary2">
        <div className="flex items-center gap-6 px-4 py-4 mx-1 my-6 rounded-lg bg-transparent hover:bg-primaryHover text-white c-pointer transition-all" onClick={cerrarSesion}>
          <LogoutIcon fill={colors.secondary} height={16} width={16} />
          <span className={`font-startup-medium text-inherit transition-all`}>Cerrar sesión</span>
        </div>
      </div>
    </div>
  ), [user?.Permission, openedReportes, location, pathCurrent, isActiveMenu, classNameIsActiveMenu, cerrarSesion, handlersReportes]);

  return (
    <>
      <div className="md:hidden col-span-1 w-full border-b border-gris-F7F7F7">
        <div className="flex flex-1 space-x-4 items-center bg-white pt-4 pb-4 px-8 border-t border-[#F5F5F5]">
          <div className="flex-1">
            <Link to={`${pathsRouter.pathAdmin}/`}>
              <Image
                src={"/assets/images/logo.svg"}
                className="w-full max-w-[160px] select-none pointer-events-none"
                loading="lazy"
              />
            </Link>
          </div>
          <div className="inline-block ml-auto c-pointer active:scale-90 transition ease-in-out" onClick={open}>
            <BarsIcon fill={colors.primary} height={28} />
          </div>
        </div>
      </div>
      <Drawer
        opened={opened}
        onClose={close}
        classNames={{
          header: "bg-primary",
          content: "bg-primary",
        }}
        closeButtonProps={{ bg: "white" }}
      // withCloseButton={false}
      >
        <div className="bg-primary pb-10 rounded-xl space-y-2 w-full">
          <div className="border-b border-primary2 flex flex-col space-y-5 items-center justify-center w-full">
            <div className="pt-4 pb-10">
              <Link to={`${pathsRouter.pathAdmin}/`}>
                <Image
                  src={"/assets/images/logo.svg"}
                  className="w-full max-w-[160px] select-none pointer-events-none"
                  loading="lazy"
                />
              </Link>
            </div>
          </div>
          <div className="border-b border-primary2 text-left py-6 px-12">
            <p className="font-startup-semibold text-sm text-secondary uppercase">{user?.Permission?.name}</p>
            <p className="font-startup-medium text-xl text-white capitalize">{(`${user?.firstName} ${user?.lastName}`)?.trim()}</p>
          </div>
          <MenuNav />
        </div>
      </Drawer>
      <aside className="hidden md:block md:col-span-4 lg:col-span-3 space-y-4 h-full">
        <div className="flex flex-col flex-1 bg-primary pt-10 pb-5 space-y-2 h-full">
          <div className="border-b border-primary2 flex flex-col space-y-5 items-center justify-start px-6">
            <div className="pt-0 pb-10">
              <Link to={`${pathsRouter.pathAdmin}/`}>
                <Image
                  src={"/assets/images/logo.svg"}
                  className="w-full max-w-[180px] mx-auto select-none pointer-events-none"
                  loading="lazy"
                />
              </Link>
            </div>
          </div>
          <div className="border-b border-primary2 text-left py-6 px-12">
            <p className="font-startup-semibold text-sm text-secondary uppercase">{user?.Permission?.name}</p>
            <p className="font-startup-medium text-xl text-white capitalize">{(`${user?.firstName} ${user?.lastName}`)?.trim()}</p>
          </div>
          <div className="flex flex-1 flex-col h-full px-6">
            <MenuNav />
          </div>
        </div>
      </aside>
    </>
  );
}, (prev, next) => prev === next);

const Grid = ({ children, classNameInner }) => {
  return (
    <section className="md:grid grid-cols-1 sm:grid-cols-12 gap-3 sm:gap-0 min-h-screen">
      <LayoutMenuLeft />
      <div className={`col-span-1 md:col-span-8 lg:col-span-9 ${classNameInner}`}>
        {children}
      </div>
    </section>
  );
}

export default memo(Grid, (prev, next) => prev === next);