import qs from 'qs';
import axios from "..";
const prefix = "admin/report_request_rewards";

export const getRequestRewards = async (id) => {
  try {
    const params = qs.stringify({
      populate: ["departments", "rewards", "users"],
      id: id,
    });
    const response = await axios.get(`/${prefix}?${params}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createReward = async (params) => {
  try {
    const response = await axios.post(`/${prefix}`, params, {
      maxBodyLength: Infinity,
      headers: { "Content-Type": "multipart/form-data" }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateStatusRequestReward = async (id, status) => {
  try {
    const params = {
      status,
    }
    const response = await axios.put(`/${prefix}/${id}`, params, {
      maxBodyLength: Infinity,
      headers: { "Content-Type": "multipart/form-data" }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteReward = async (id) => {
  try {
    const response = await axios.delete(`/${prefix}/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
