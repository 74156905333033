import React from 'react';

const CustomStatusBadge = ({ status }) => {
  const getStatusClasses = () => {
    switch (status.toLowerCase()) {
      case 'entregada':
        return 'bg-green-500 text-dark';
      case 'pendiente':
        return 'bg-yellow-500 text-dark';
      default:
        return 'bg-gray-500 text-dark';
    }
  };

  return (
    <span
      className={`font-startup-regular capitalize inline-block px-3 py-1.5 rounded-full text-[0.802rem] ${getStatusClasses()}`}
    >
      {status}
    </span>
  );
};

export default CustomStatusBadge;