import React from 'react';
import Unathorized from '../pages/Errors/Unauthorized';

/**
 * Verifies whether the user has the required permissions.
 *
 * @param {Object} permissions - The user's permissions.
 * @param {Array} requiredPermissions - The permissions required to access the component.
 * @param {string} [logicalOperator='AND'] - The logical operator ('AND' or 'OR') to apply to requiredPermissions.
 * @returns {boolean} - Returns true if the user has the required permissions, false otherwise.
 *
 * @example
 * // Example with 'AND' operator
 * const isAuthorized = verifyPermissions({ leer: true, actualizar: false }, ['leer', 'actualizar']);
 * // Returns false because the user must have both 'leer' and 'actualizar' permissions.
 *
 * @example
 * // Example with 'OR' operator
 * const isAuthorized = verifyPermissions({ leer: true, actualizar: false }, ['leer', 'actualizar'], 'OR');
 * // Returns true because the user has 'leer' permission even though 'actualizar' is false.
*/
const verifyPermissions = (permissions, requiredPermissions, logicalOperator = 'AND') => {
  // If either permissions or requiredPermissions is false, user is not authorized
  if (!permissions || !requiredPermissions) return false;

  if (logicalOperator === 'AND') {
    // Check if all requiredPermissions are present and have a truthy value
    return requiredPermissions.every(
      (permission) => permissions[permission] === true || permissions[permission] === 'true'
    );
  } else if (logicalOperator === 'OR') {
    // Check if at least one requiredPermission is present and has a truthy value
    return requiredPermissions.some(
      (permission) => permissions[permission] === true || permissions[permission] === 'true'
    );
  }

  // If logicalOperator is neither 'AND' nor 'OR', user is not authorized
  return false;
};

/**
 * A function that authorizes access based on user permissions.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.permissions - The user's permissions.
 * @param {Array} props.requiredPermissions - The permissions required to access the component.
 * @param {string} [props.logicalOperator='AND'] - The logical operator ('AND' or 'OR') to apply to requiredPermissions.
 * @returns {boolean} - Returns true if the user has the required permissions, false otherwise.
 *
 * @example
 * // Example with 'AND' operator
 * const isAuthorized = hasPermissions({ permissions: { leer: true, actualizar: false }, requiredPermissions: ['leer', 'actualizar'] });
 * // Returns false because the user must have both 'leer' and 'actualizar' permissions.
 *
 * @example
 * // Example with 'OR' operator
 * const isAuthorized = hasPermissions({ permissions: { leer: true, actualizar: false }, requiredPermissions: ['leer', 'actualizar'], logicalOperator: 'OR' });
 * // Returns true because the user has 'leer' permission even though 'actualizar' is false.
 */
export const hasPermissions = ({
  permissions,
  requiredPermissions,
  logicalOperator = 'AND',
}) => {
  /**
   * @type {boolean} - Indicates whether the user is authorized based on the provided permissions.
   */
  return verifyPermissions(permissions, requiredPermissions, logicalOperator);
};

/**
 * A component that authorizes access based on user permissions.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.permissions - The user's permissions.
 * @param {Array} props.requiredPermissions - The permissions required to access the component.
 * @param {string} [props.logicalOperator='AND'] - The logical operator ('AND' or 'OR') to apply to requiredPermissions.
 * @param {React.ReactNode} props.children - The content to render if the user is authorized.
 * @returns {React.ReactNode} - Returns the children if authorized, or a forbidden message otherwise.
 *
 * @example
 * // Example with 'AND' operator
 * <Authorizer permissions={{ leer: true, actualizar: false }} requiredPermissions={['leer', 'actualizar']}>
 *   <div>Contenido autorizado</div>
 * </Authorizer>
 *
 * @example
 * // Example with 'OR' operator
 * <Authorizer permissions={{ leer: true, actualizar: false }} requiredPermissions={['leer', 'actualizar']} logicalOperator="OR">
 *   <div>Contenido autorizado</div>
 * </Authorizer>
 */
export const Authorizer = ({
  permissions,
  requiredPermissions,
  logicalOperator = 'AND',
  children,
}) => {
  /**
   * @type {boolean} - Indicates whether the user is authorized based on the provided permissions.
   */
  const isAuthorized = verifyPermissions(permissions, requiredPermissions, logicalOperator);

  if (isAuthorized) {
    return children;
  }

  return (
    <>
      <Unathorized />
    </>
  );
};