import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Button, Checkbox, FileButton, Group, Image, LoadingOverlay, MultiSelect, NumberInput, Select, SimpleGrid, Stack, Text, TextInput, Textarea } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { notifications } from '@mantine/notifications';
import SEO from '../../../components/SEO';
import { ButtonCustom } from '../../../components/Button';
import { useCustomState } from '../../../hooks/useCustomState';
import Grid from '../components/Grid';
import { createAccion, getAcciones, updateAccion } from '../../../api/admin/acciones';
import { getTipoAcciones } from '../../../api/admin/tiposAcciones';
import { getDepartamentos } from '../../../api/admin/departamentos';
import { isEmpty } from '../../../utils';
import { dayjs_custom } from '../../../utils/dayjs_custom';
import parseErrorMessage from '../../../utils/parseErrorMessage';
import getPreviewImage from '../../../utils/getPreviewImage';
import pathsRouter from '../../../router/pathsRouter';

import 'dayjs/locale/es';
import { colors } from '../../../theme/colors';
const prefix = "acciones";

const ModificarAccion = ({ isEditar, isCrear }) => {
  const isDisabled = (!isEditar && !isCrear);
  const { id } = useParams();
  const navigate = useNavigate();
  const user_store = useSelector(state => state?.admin?.auth?.user?.user || state?.admin?.auth?.user);
  const [state, setState] = useCustomState({
    loading: true,
    sending: false,
    sending_draft: false,
    isDraft: false,
    nombre_accion: "",
    descripcion_breve: "",
    descripcion: "",
    price: "",
    vigencia: undefined,
    imageFile: null,
    imageFilePreview: null,
    answer_text: false,
    answer_image: false,
    answer_audio: false,
    acciones: [],
    tiposAcciones: [],
    tipo_accion_seleccionado: null,
    departamentos: [],
    departamento_seleccionado: [],
  });

  useEffect(() => {
    loadData(id);
    // eslint-disable-next-line
  }, [id]);

  const loadData = useCallback(async (_id) => {
    const tiposAcciones = await getTipoAcciones();
    const departamentos = await getDepartamentos();
    const acciones = (_id) ? await getAcciones(_id) : null;
    const _accion = (_id) ? acciones?.data?.[0] : null;

    const params_editar = _id ? {
      isDraft: _accion?.isDraft,
      tiposAcciones: (tiposAcciones?.data)?.map(item => ({ label: item?.name, value: (item?.id)?.toString() })),
      departamentos: (departamentos?.data)?.map(item => ({ label: item?.name, value: (item?.id)?.toString() })),
      nombre_accion: _accion?.name,
      descripcion_breve: _accion?.summary,
      descripcion: _accion?.description,
      price: _accion?.price,
      answer_text: _accion?.requireTextAnswer,
      answer_image: _accion?.requireImageAnswer,
      answer_audio: _accion?.requireAudioAnswer,
      imageFilePreview: _accion?.image,
      vigencia: [dayjs_custom(_accion?.validityStart || null)?.toDate(), dayjs_custom(_accion?.validityEnd || null)?.toDate()],
      tipo_accion_seleccionado: _accion?.typeID?.toString(),
      departamento_seleccionado: _accion?.ActionsDepartments?.map(item => item?.departmentID?.toString()),
    } : {
      tiposAcciones: (tiposAcciones?.data)?.map(item => ({ label: item?.name, value: (item?.id)?.toString() })),
      departamentos: (departamentos?.data)?.map(item => ({ label: item?.name, value: (item?.id)?.toString() })),
    };

    setState({
      loading: false,
      ...params_editar,
    });
  }, [setState]);

  const message = useCallback((title, message) => {
    notifications.show({
      title: title,
      message: message,
    });
  }, []);

  const onCreate = useCallback(async (isDraft = false) => {
    if (isEditar || isCrear) {
      if (isEmpty(state.nombre_accion)) return message(null, 'El nombre es obligatorio.');
      if (isEmpty(state.tipo_accion_seleccionado)) return message(null, 'Selecciona un tipo para continuar.');

      try {
        setState(isDraft ? { sending_draft: true } : { sending: true });

        const formDataFields = {
          isDraft: isDraft ? (!state.isDraft)?.toString() : "false",
          typeID: state?.tipo_accion_seleccionado,
          departmentID: state?.departamento_seleccionado,
          name: state?.nombre_accion,
          summary: state?.descripcion_breve,
          description: state?.descripcion,
          price: state?.price,
          requireTextAnswer: state?.answer_text ? "true" : "false",
          requireImageAnswer: state?.answer_image ? "true" : "false",
          requireAudioAnswer: state?.answer_audio ? "true" : "false",
          image: (state.imageFile && state.imageFile),
          validityStart: state?.vigencia?.[0],
          validityEnd: state?.vigencia?.[1],
        };

        const _formData = new FormData();

        Object.entries(formDataFields).forEach(([key, value]) => {
          if (value) {
            if (Array.isArray(value)) {
              if (["image"].includes(key)) {
                value.forEach(item => _formData.append(key, item));
              } else {
                value.forEach(item => _formData.append(key + '[]', item));
              }
            } else {
              _formData.append(key, value);
            }
          }
        });

        const response = isCrear ? await createAccion(_formData) : await updateAccion(id, _formData);

        setState(isDraft ? { sending_draft: false } : { sending: false });
        if (response?.status === true) {
          if (isDraft) {
            message(state.isDraft ? "Acción publicada" : "Guardado en borradores", `¡La acción se ha ${state.isDraft ? "publicado" : "guardado en borradores"} exitosamente!`);
            setState({ isDraft: !state.isDraft });
          } else {
            message(`Acción ${isCrear ? "creada" : "actualizada"}`, `¡La acción se ha ${isCrear ? "creado" : "actualizado"} exitosamente!`);
          }
          navigate(`${pathsRouter.pathAdmin}/${prefix}`);
        } else {
          message('Ocurrio un problema', parseErrorMessage(response) || 'Lo sentimos, ocurrió un error con la solicitud. Por favor, inténtalo de nuevo más tarde.');
        }
      } catch (error) {
        setState(isDraft ? { sending_draft: false } : { sending: false });
        message('Ocurrio un problema', parseErrorMessage(error) || 'Lo sentimos, ocurrió un error inesperado. Por favor, inténtalo de nuevo más tarde.');
      }
    }

    // eslint-disable-next-line
  }, [isEditar, isCrear, id, user_store, state, setState, navigate, message]);

  return (
    <>
      <SEO
        title={isEditar ? "Editar acción" : (isCrear ? "Crear nueva acción" : "Ver acción")}
      />
      <Grid>
        <div className='bg-bg h-full pb-4'>
          <div className='bg-white px-6 lg:px-8 py-6 shadow-sm flex flex-wrap items-center justify-center md:justify-between gap-4'>
            <h1 className='text-primary font-startup-semibold text-3xl text-center md:text-left'>{isEditar ? "Editar acción" : (isCrear ? "Crear nueva acción" : "Ver acción")}</h1>
          </div>
          <div className='px-6 lg:px-8'>
            <div className='relative flex flex-col bg-white mt-10 px-8 py-10 rounded-lg w-full'>
              <LoadingOverlay visible={state.loading} zIndex={1000} overlayProps={{ radius: "sm", blur: 1 }} />
              <SimpleGrid
                cols={{ base: 1, sm: 2, lg: 2 }}
                spacing={{ base: 10, sm: 'xl' }}
                verticalSpacing={{ base: 'md', sm: 'xl' }}
              >
                <div className='flex flex-col gap-5 mb-6'>
                  <TextInput
                    size="md"
                    variant='filled'
                    label="Nombre de acción"
                    placeholder="Escribe"
                    labelProps={{
                      ff: "Startup-Medium",
                      fw: 500,
                      mb: 5,
                      pl: 15
                    }}
                    classNames={{
                      label: "text-sm",
                      input: "h-auto py-1 text-sm",
                    }}
                    value={state?.nombre_accion}
                    onChange={(event) => setState({ nombre_accion: event.currentTarget.value })}
                    disabled={isDisabled}
                  />
                  <TextInput
                    size="md"
                    variant='filled'
                    label="Descripción breve"
                    placeholder="Escribe"
                    labelProps={{
                      ff: "Startup-Medium",
                      fw: 500,
                      mb: 5,
                      pl: 15
                    }}
                    classNames={{
                      label: "text-sm",
                      input: "h-auto py-1 text-sm",
                    }}
                    maxLength={255}
                    value={state?.descripcion_breve}
                    onChange={(event) => setState({ descripcion_breve: event.currentTarget.value })}
                    disabled={isDisabled}
                  />
                  <Textarea
                    size="md"
                    variant='filled'
                    label="Descripción"
                    placeholder="Escribe"
                    labelProps={{
                      ff: "Startup-Medium",
                      fw: 500,
                      mb: 5,
                      pl: 15
                    }}
                    classNames={{
                      label: "text-sm",
                      input: "text-sm",
                    }}
                    value={state?.descripcion}
                    onChange={(event) => setState({ descripcion: event.currentTarget.value })}
                    disabled={isDisabled}
                  />
                  <NumberInput
                    size="md"
                    variant='filled'
                    label="Prosapesos"
                    placeholder="0"
                    clampBehavior="strict"
                    min={1}
                    max={9999}
                    labelProps={{
                      ff: "Startup-Medium",
                      fw: 500,
                      mb: 5,
                      pl: 15
                    }}
                    classNames={{
                      label: "text-sm",
                      input: "h-auto py-1 text-sm",
                    }}
                    value={state?.price}
                    onChange={(value) => setState({ price: value })}
                    disabled={isDisabled}
                  />
                </div>
                <div className='flex flex-col gap-5 mb-6'>
                  <DatePickerInput
                    allowSingleDateInRange
                    locale="es"
                    type="range"
                    valueFormat="DD MMM YYYY"
                    label="Vigencia"
                    variant='filled'
                    placeholder="Seleccionar"
                    labelProps={{
                      ff: "Startup-Medium",
                      fw: 500,
                      mb: 5,
                      pl: 15
                    }}
                    classNames={{
                      root: "text-sm",
                      monthThead: "text-sm",
                      label: "text-sm",
                      input: "h-auto py-2.5 text-sm capitalize",
                    }}
                    disabled={isDisabled}
                    value={state.vigencia}
                    onChange={(value) => setState({ vigencia: value })}
                  />
                  <Select
                    size="md"
                    variant='filled'
                    label="Tipo"
                    placeholder="Selecciona"
                    data={state.tiposAcciones}
                    labelProps={{
                      ff: "Startup-Medium",
                      fw: 500,
                      mb: 5,
                      pl: 15
                    }}
                    classNames={{
                      label: "text-sm",
                      input: "h-auto py-1 text-sm",
                    }}
                    value={state.tipo_accion_seleccionado}
                    onChange={(value) => setState({ tipo_accion_seleccionado: value })}
                    disabled={isDisabled}
                  />
                  <MultiSelect
                    size="md"
                    variant='filled'
                    placeholder="Selecciona"
                    data={state.departamentos}
                    label="Departamentos"
                    labelProps={{
                      ff: "Startup-Medium",
                      fw: 500,
                      mb: 5,
                      pl: 15
                    }}
                    classNames={{
                      label: "text-sm",
                      root: "max-w-[420px] w-full text-sm",
                      input: "flex items-center",
                      inputField: "text-sm !ring-0 !outline-none"
                    }}
                    rightSectionPointerEvents='none'
                    value={state.departamento_seleccionado}
                    onChange={(value) => setState({ departamento_seleccionado: value })}
                    disabled={isDisabled}
                  />
                </div>
              </SimpleGrid>
              <div className='flex flex-wrap gap-5 mt-6 mb-6'>
                <Group align='flex-start' gap="xl" grow={true}>
                  <Checkbox
                    className='select-none'
                    classNames={{
                      input: "!ring-0 !ring-offset-0 !outline-none !shadow-none"
                    }}
                    label="Requiere respuesta en texto"
                    checked={state.answer_text || false}
                    onChange={(event) => setState({ answer_text: event.currentTarget.checked })}
                    disabled={isDisabled}
                  />
                  <Checkbox
                    className='select-none'
                    classNames={{
                      input: "!ring-0 !ring-offset-0 !outline-none !shadow-none"
                    }}
                    label="Requiere evidencia en imagen"
                    checked={state.answer_image || false}
                    onChange={(event) => setState({ answer_image: event.currentTarget.checked })}
                    disabled={isDisabled}
                  />
                  <Checkbox
                    className='select-none'
                    classNames={{
                      input: "!ring-0 !ring-offset-0 !outline-none !shadow-none"
                    }}
                    label="Requiere evidencia con audio"
                    pointer
                    checked={state.answer_audio || false}
                    onChange={(event) => setState({ answer_audio: event.currentTarget.checked })}
                    disabled={isDisabled}
                  />
                </Group>
              </div>
              <div className='flex flex-col gap-5 mb-6'>
                {!isDisabled && (
                  <Stack gap="xs">
                    <Group justify="flex-start">
                      <FileButton
                        accept="image/png,image/jpeg"
                        className={`h-auto flex items-center font-startup-medium py-2.5 border-primary bg-primary hover:!bg-primaryHover hover:!border-primaryHover text-xs px-6`}
                        onChange={file => {
                          setState({ imageFile: file });
                          getPreviewImage(file, (image) => setState({ imageFilePreview: image }));
                        }}
                        disabled={isDisabled}
                      >
                        {(props) => <Button {...props}>{(state?.imageFile || state?.imageFilePreview) ? "Cambiar imagen" : "Subir imagen"}</Button>}
                      </FileButton>
                    </Group>
                  </Stack>
                )}
                <Box className='relative' radius="md" maw={260}>
                  <Image
                    className='bg-gray-100 max-h-[320px]'
                    radius="md"
                    fit="contain"
                    alt="Preview portada"
                    src={state?.imageFilePreview ? state?.imageFilePreview : null}
                    fallbackSrc="https://placehold.co/600x300?text=Placeholder"
                  />
                  {state?.imageFile && (
                    <div className='bg-black/40 absolute top-0 left-0 rounded-t-lg w-full'>
                      <Text className='text-sm text-white font-startup font-semibold p-2'>{state?.imageFile?.name}</Text>
                    </div>
                  )}
                </Box>
              </div>
              <div className='flex flex-wrap justify-center sm:justify-end items-center gap-3 mt-14'>
                <ButtonCustom
                  text={"REGRESAR"}
                  classNameButton="border-stone-950 bg-stone-950 !text-neutral-200 hover:!bg-stone-900 hover:!border-stone-900 text-xs px-6"
                  onClick={() => navigate(`${pathsRouter.pathAdmin}/${prefix}`)}
                />
                {(isEditar || isCrear) && (
                  <>
                    <ButtonCustom
                      text={state.isDraft ? "PUBLICAR" : "GUARDAR COMO BORRADOR"}
                      classNameButton="border-primary bg-white hover:!bg-gray-100 hover:!border-primaryHover text-primary text-xs px-6"
                      loader={{
                        color: colors.text
                      }}
                      onClick={() => onCreate(true)}
                      loading={state.sending_draft}
                    />
                    <ButtonCustom
                      text={isEditar ? "ACTUALIZAR" : "CREAR"}
                      classNameButton="border-primary bg-primary hover:!bg-primaryHover hover:!border-primaryHover text-xs px-6"
                      onClick={() => onCreate(false)}
                      loading={state.sending}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </>
  );
};

export default ModificarAccion;