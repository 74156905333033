import qs from 'qs';
import axios from "..";
const prefix = "admin/notices";

export const getNotices = async (id) => {
  try {
    const params = qs.stringify({
      id: id,
    });
    const response = await axios.get(`/${prefix}?${params}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createNotice = async (params) => {
  try {
    const response = await axios.post(`/${prefix}`, params, {
      maxBodyLength: Infinity,
      headers: { "Content-Type": "multipart/form-data" }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateNotice = async (id, params) => {
  try {
    const response = await axios.put(`/${prefix}/${id}`, params, {
      maxBodyLength: Infinity,
      headers: { "Content-Type": "multipart/form-data" }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteNotice = async (id) => {
  try {
    const response = await axios.delete(`/${prefix}/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
