import React, { Fragment, useLayoutEffect, memo } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import pathsRouter from './pathsRouter';
import { Authorizer } from './RequireAuth';

import ScrollToTop from '../utils/ScrollToTop';
import updateUserStore from '../utils/updateUserStore';

import NoEncontrado from "../pages/Errors/404";

import AdminHome from '../pages/Admin/Home';
import Login from '../pages/Admin/Login';
import RecuperarCuenta from '../pages/Admin/RecuperarCuenta';
import ActualizarContrasenia from '../pages/Admin/ActualizarContrasenia';

import Usuarios from '../pages/Admin/Usuarios';
import ModificarUsuario from '../pages/Admin/Usuarios/ModificarUsuario';
import Permisos from '../pages/Admin/Permisos';
import ModificarPermiso from '../pages/Admin/Permisos/ModificarPermiso';
import Colaboradores from '../pages/Admin/Colaboradores';
import Acciones from '../pages/Admin/Acciones';
import ModificarAccion from '../pages/Admin/Acciones/ModificarAccion';
import Recompensas from '../pages/Admin/Recompensas';
import ModificarRecompensa from '../pages/Admin/Recompensas/ModificarRecompensa';
import ReporteParticipacion from '../pages/Admin/Reportes/ReporteParticipacion';
import DetallesParticipacion from '../pages/Admin/Reportes/DetallesParticipacion';
import ReporteProsapesos from '../pages/Admin/Reportes/ReporteProsapesos';
import ReporteSolicitudRecompensas from '../pages/Admin/Reportes/ReporteSolicitudRecompensas';
import DetallesRecompensa from '../pages/Admin/Reportes/DetallesRecompensa';
import Avisos from '../pages/Admin/Avisos';
import ModificarAviso from '../pages/Admin/Avisos/ModificarAviso';

function RootRouter(props) {
  const dispatch = useDispatch();
  const {
    admin: { auth: admin },
  } = useSelector(state => state);
  const _userAdmin = admin?.user?.user || admin?.user;

  const { pathAdmin } = pathsRouter;
  const usuariosPath = `${pathAdmin}/usuarios`;

  const createAuthorizerRoute = (path, component, permissions, requiredPermissions, logicalOperator) => (
    <Route exact path={path} element={<Authorizer permissions={_userAdmin?.Permission?.[permissions]} requiredPermissions={requiredPermissions} logicalOperator={logicalOperator}>{component}</Authorizer>} />
  );

  useLayoutEffect(() => {
    updateUserStore(_userAdmin, dispatch);
    // eslint-disable-next-line
  }, [_userAdmin?.id, _userAdmin?.email]);

  return (
    <Router>
      <Fragment>
        <ScrollToTop />
        <Routes>
          <Route exact index path={pathAdmin} element={admin.isAuthenticated ? <AdminHome /> : <Login />} />
          <Route exact path={`${pathAdmin}/login`} element={<Login />} />
          <Route exact path={`${pathAdmin}/recuperar-contrasenia`} element={<RecuperarCuenta />} />
          <Route exact path={`${pathAdmin}/actualizar-contrasenia`} element={<ActualizarContrasenia />} />

          {admin.isAuthenticated && (
            <>
              {createAuthorizerRoute(usuariosPath, <Usuarios />, "usersPermissions", ["leer"])}
              {createAuthorizerRoute(`${usuariosPath}/:id`, <ModificarUsuario />, "usersPermissions", ["leer"])}
              {createAuthorizerRoute(`${usuariosPath}/crear-usuario`, <ModificarUsuario isCrear />, "usersPermissions", ["crear"])}
              {createAuthorizerRoute(`${usuariosPath}/editar-usuario/:id`, <ModificarUsuario isEditar />, "usersPermissions", ["actualizar"])}
              {createAuthorizerRoute(`${pathAdmin}/permisos`, <Permisos />, "permissionsPermissions", ["leer"])}
              {createAuthorizerRoute(`${pathAdmin}/permisos/:id`, <ModificarPermiso />, "permissionsPermissions", ["leer"])}
              {createAuthorizerRoute(`${pathAdmin}/permisos/crear-permiso`, <ModificarPermiso isCrear />, "permissionsPermissions", ["crear"])}
              {createAuthorizerRoute(`${pathAdmin}/permisos/editar-permiso/:id`, <ModificarPermiso isEditar />, "permissionsPermissions", ["actualizar"])}
              {createAuthorizerRoute(`${pathAdmin}/colaboradores`, <Colaboradores />, "collaboratorsPermissions", ["leer"])}
              {createAuthorizerRoute(`${pathAdmin}/acciones`, <Acciones />, "actionsPermissions", ["leer"])}
              {createAuthorizerRoute(`${pathAdmin}/acciones/:id`, <ModificarAccion />, "actionsPermissions", ["leer"])}
              {createAuthorizerRoute(`${pathAdmin}/acciones/crear-accion`, <ModificarAccion isCrear />, "actionsPermissions", ["crear"])}
              {createAuthorizerRoute(`${pathAdmin}/acciones/editar-accion/:id`, <ModificarAccion isEditar />, "actionsPermissions", ["actualizar"])}
              {createAuthorizerRoute(`${pathAdmin}/recompensas`, <Recompensas />, "rewardsPermissions", ["leer"])}
              {createAuthorizerRoute(`${pathAdmin}/recompensas/:id`, <ModificarRecompensa />, "rewardsPermissions", ["leer"])}
              {createAuthorizerRoute(`${pathAdmin}/recompensas/crear-recompensa`, <ModificarRecompensa isCrear />, "rewardsPermissions", ["crear"])}
              {createAuthorizerRoute(`${pathAdmin}/recompensas/editar-recompensa/:id`, <ModificarRecompensa isEditar />, "rewardsPermissions", ["actualizar"])}
              {createAuthorizerRoute(`${pathAdmin}/reportes_participacion`, <ReporteParticipacion />, "reportsPermissions", ["leer"])}
              {createAuthorizerRoute(`${pathAdmin}/reportes_participacion/detalles/:id`, <DetallesParticipacion />, "reportsPermissions", ["leer", "actualizar"], "OR")}
              {createAuthorizerRoute(`${pathAdmin}/reportes_prosapesos`, <ReporteProsapesos />, "reportsPermissions", ["leer"])}
              {createAuthorizerRoute(`${pathAdmin}/reportes_recompensas`, <ReporteSolicitudRecompensas />, "reportsPermissions", ["leer"])}
              {createAuthorizerRoute(`${pathAdmin}/reportes_recompensas/detalles/:id`, <DetallesRecompensa />, "reportsPermissions", ["leer", "actualizar"], "OR")}
              {createAuthorizerRoute(`${pathAdmin}/avisos`, <Avisos />, "noticesPermissions", ["leer"])}
              {createAuthorizerRoute(`${pathAdmin}/avisos/crear-aviso`, <ModificarAviso isCrear />, "noticesPermissions", ["crear"])}
              {createAuthorizerRoute(`${pathAdmin}/avisos/editar-aviso/:id`, <ModificarAviso isEditar />, "noticesPermissions", ["actualizar"])}
            </>
          )}

          <Route path="*" element={<NoEncontrado />} />
        </Routes>
      </Fragment>
    </Router>
  );
}

export default memo(RootRouter);