import React, {useCallback, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Box, Button, FileButton, Group, Image, NumberInput, Stack, Text, Textarea, TextInput} from '@mantine/core';
import {notifications} from '@mantine/notifications';
import SEO from '../../../components/SEO';
import {ButtonCustom} from '../../../components/Button';
import {useCustomState} from '../../../hooks/useCustomState';
import Grid from '../components/Grid';
import {isEmpty} from '../../../utils';
import getPreviewImage from '../../../utils/getPreviewImage';
import pathsRouter from '../../../router/pathsRouter';
import parseErrorMessage from "../../../utils/parseErrorMessage";
import {createReward, getRewards, updateReward} from "../../../api/admin/rewards";

const prefix = "recompensas";

const ModificarRecompensa = ({isEditar, isCrear}) => {
    const isDisabled = (!isEditar && !isCrear);
    const navigate = useNavigate();
    const {id} = useParams();
    const [state, setState] = useCustomState({
        sending: false,
        name: "",
        description: "",
        price: "",
        stock: "",
        imageFile: null,
        imageFilePreview: null,
    });

    const loadData = useCallback(async (_id) => {
        const rewards = (_id) ? await getRewards(_id) : null;
        const _reward = (_id) ? rewards?.data?.[0] : null;

        const params_edit = {
            name: _reward?.name,
            description: _reward?.description,
            price: _reward?.price,
            stock: _reward?.stock,
            imageFilePreview: _reward?.image,
        };

        setState({
            loading: false,
            ...params_edit,
        });
    }, [setState]);

    useEffect(() => {
        loadData(id);
    }, [id, loadData]);

    const message = useCallback((title, message) => {
        notifications.show({
            title: title,
            message: message,
        });
    }, []);

    const onCreate = useCallback(async () => {
        if (isEmpty(state.name)) return message(null, 'El nombre de la recompensa es obligatoria.');
        if (isEmpty(state.price)) return message(null, 'Los prosapesos de la recompensa son obligatorios.');
        if (isEmpty(state.stock)) return message(null, 'El inventario de la recompensa es obligatoria.');

        try {
            const _formData = new FormData();

            const formDataFields = {
                name: state?.name,
                description: state?.description,
                price: state?.price,
                stock: state?.stock,
                image: (state.imageFile && state.imageFile),
            };

            Object.entries(formDataFields).forEach(([key, value]) => {
                if (value) {
                    if (Array.isArray(value)) {
                        if (["image"].includes(key)) {
                            value.forEach(item => _formData.append(key, item));
                        } else {
                            value.forEach(item => _formData.append(key + '[]', item));
                        }
                    } else {
                        _formData.append(key, value);
                    }
                }
            });
            const response = isCrear ? await createReward(_formData) : await updateReward(id, _formData);

            setState({sending: false});
            if (response?.status === true) {
                message(`Recompensa ${isCrear ? "creada" : "actualizada"}`, `¡La recompensa se ha ${isCrear ? "creado" : "actualizado"} exitosamente!`);
                navigate(`${pathsRouter.pathAdmin}/${prefix}`);
            } else {
                message('Ocurrio un problema', parseErrorMessage(response) || 'Lo sentimos, ocurrió un error con la solicitud. Por favor, inténtalo de nuevo más tarde.');
            }
        } catch (error) {
            setState({sending: false});
            message('Ocurrio un problema', parseErrorMessage(error) || 'Lo sentimos, ocurrió un error inesperado. Por favor, inténtalo de nuevo más tarde.');
        }
        // eslint-disable-next-line
    }, [
        state.name, state.description, state.price, state.stock, state.imageFile, state.imageFilePreview,
        setState, navigate, message
    ]);

    return (
        <>
            <SEO
                title={isEditar ? "Editar recompensa" : (isCrear ? "Crear nueva recompensa" : "Ver recompensa")}
            />
            <Grid>
                <div className='bg-bg h-full pb-4'>
                    <div
                        className='bg-white px-6 lg:px-8 py-6 shadow-sm flex flex-wrap items-center justify-center md:justify-between gap-4'>
                        <h1 className='text-primary font-startup-semibold text-3xl text-center md:text-left'>{isEditar ? "Editar recompensa" : (isCrear ? "Crear nueva recompensa" : "Ver recompensa")}</h1>
                    </div>
                    <div className='px-6 lg:px-8'>
                        <div
                            className='flex flex-col bg-white mt-10 px-8 py-10 rounded-lg mx-auto md:mx-0 max-w-md w-full'>
                            <div className='flex flex-col gap-5 mb-6'>
                                <TextInput
                                    size="md"
                                    variant='filled'
                                    label="Nombre de recompensa"
                                    placeholder="Escribe"
                                    labelProps={{
                                        ff: "Startup-Medium",
                                        fw: 500,
                                        mb: 5,
                                        pl: 15
                                    }}
                                    classNames={{
                                        label: "text-sm",
                                        input: "h-auto py-1 text-sm",
                                    }}
                                    value={state?.name}
                                    onChange={(event) => setState({name: event.currentTarget.value})}
                                    disabled={isDisabled}
                                />
                                <Textarea
                                    size="md"
                                    variant='filled'
                                    label="Descripción"
                                    placeholder="Escribe"
                                    labelProps={{
                                        ff: "Startup-Medium",
                                        fw: 500,
                                        mb: 5,
                                        pl: 15
                                    }}
                                    classNames={{
                                        label: "text-sm",
                                        input: "text-sm",
                                    }}
                                    value={state?.description}
                                    onChange={(event) => setState({description: event.currentTarget.value})}
                                    disabled={isDisabled}
                                />
                                <NumberInput
                                    size="md"
                                    variant='filled'
                                    label="Prosapesos"
                                    placeholder="0"
                                    clampBehavior="strict"
                                    min={1}
                                    max={9999}
                                    labelProps={{
                                        ff: "Startup-Medium",
                                        fw: 500,
                                        mb: 5,
                                        pl: 15
                                    }}
                                    classNames={{
                                        label: "text-sm",
                                        input: "h-auto py-1 text-sm",
                                    }}
                                    value={state?.price}
                                    onChange={(value) => setState({price: value})}
                                    disabled={isDisabled}
                                />
                                <NumberInput
                                    size="md"
                                    variant='filled'
                                    label="Inventario"
                                    placeholder="0"
                                    clampBehavior="strict"
                                    min={1}
                                    max={9999}
                                    labelProps={{
                                        ff: "Startup-Medium",
                                        fw: 500,
                                        mb: 5,
                                        pl: 15
                                    }}
                                    classNames={{
                                        label: "text-sm",
                                        input: "h-auto py-1 text-sm",
                                    }}
                                    value={state?.stock}
                                    onChange={(value) => setState({stock: value})}
                                    disabled={isDisabled}
                                />
                                {!isDisabled && (
                                    <Stack gap="xs">
                                        <Group justify="flex-start">
                                            <FileButton
                                                accept="image/png,image/jpeg"
                                                className={`h-auto flex items-center font-startup-medium py-2.5 border-primary bg-primary hover:!bg-primaryHover hover:!border-primaryHover text-xs px-6`}
                                                onChange={file => {
                                                    setState({imageFile: file});
                                                    getPreviewImage(file, (image) => setState({imageFilePreview: image}));
                                                }}
                                                disabled={isDisabled}
                                            >
                                                {(props) =>
                                                    <Button {...props}>{state?.imageFile || state?.imageFilePreview ? "Cambiar imagen" : "Subir imagen"}</Button>}
                                            </FileButton>
                                        </Group>
                                    </Stack>
                                )}
                                <Box className='relative' radius="md" maw={260}>
                                    <Image
                                        className='bg-gray-100 max-h-[320px]'
                                        radius="md"
                                        fit="contain"
                                        alt="Preview portada"
                                        src={state?.imageFilePreview ? state?.imageFilePreview : null}
                                        fallbackSrc="https://placehold.co/600x300?text=Placeholder"
                                    />
                                    {state?.imageFile && (
                                        <div className='bg-black/40 absolute top-0 left-0 rounded-t-lg w-full'>
                                            <Text
                                                className='text-sm text-white font-startup font-semibold p-2'>{state?.imageFile?.name}</Text>
                                        </div>
                                    )}
                                </Box>
                            </div>
                            <div className='flex flex-wrap justify-center sm:justify-end items-center gap-3 mt-14'>
                                <ButtonCustom
                                    text={"REGRESAR"}
                                    classNameButton="border-stone-950 bg-stone-950 !text-neutral-200 hover:!bg-stone-900 hover:!border-stone-900 text-xs px-6"
                                    onClick={() => navigate(`${pathsRouter.pathAdmin}/${prefix}`)}
                                />
                                {(isEditar || isCrear) && (
                                    <ButtonCustom
                                        text={isEditar ? "ACTUALIZAR" : "GUARDAR"}
                                        classNameButton="border-primary bg-primary hover:!bg-primaryHover hover:!border-primaryHover text-xs px-6"
                                        onClick={onCreate}
                                        loading={state.sending}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Grid>
        </>
    );
};

export default ModificarRecompensa;