import qs from 'qs';
import axios from "..";
const prefix = "admin/report_participation";

export const getReportParticipation = async (id) => {
  try {
    const params = qs.stringify({
      id: id,
      populate: [
          'users',
          'departments',
          'actions',
      ]
    });
    const response = await axios.get(`/${prefix}?${params}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const assignRewardsWallet = async (id, priceReward) => {
  try {
    const body = {
      priceReward,
    }
    const response = await axios.put(`/${prefix}/${id}`, body);
    return response.data;
  } catch (error) {
    throw error;
  }
};
