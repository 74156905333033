import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import sortBy from 'lodash/sortBy';
import { DataTable } from 'mantine-datatable';
import { Box, Button, Group } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { ReactComponent as AddIcon } from '../../../assets/icons/add-icon.svg';
import { ReactComponent as VerIcon } from '../../../assets/icons/accion01-ver.svg';
import { ReactComponent as EditarIcon } from '../../../assets/icons/accion02-editar.svg';
import { ReactComponent as EliminarIcon } from '../../../assets/icons/accion03-eliminar.svg';
import SEO from '../../../components/SEO';
import { ButtonCustom } from '../../../components/Button';
import Loading from '../../../components/Loading';
import EliminarModal from '../../../components/Modal/EliminarModal';
import { useCustomState } from '../../../hooks/useCustomState';
import Grid from '../components/Grid';
import pathsRouter from '../../../router/pathsRouter';
import { hasPermissions } from '../../../router/RequireAuth';
import {deleteReward, getRewards} from "../../../api/admin/rewards";
const prefix = "recompensas";

const Recompensas = () => {
  const [openedModalEliminar, { close: closeModalEliminar, open: openModalEliminar }] = useDisclosure(false);
  const dataSelectedModal = useRef(null);
  const navigate = useNavigate();
  const user_store = useSelector(state => state?.admin?.auth?.user?.user || state?.admin?.auth?.user);
  const [state, setState] = useCustomState({
    sending: false,
    loading: true,
    page: 1,
    page_size: 10,
    totalRecords: 0,
    sortStatus: {},
    rowSelection: [],
    recompensas: [],
  });

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  const loadData = useCallback(async () => {
    const rewards = await getRewards();

    setState({
      recompensas: rewards?.data,
      totalRecords: (rewards?.data)?.length,
      loading: false,
    });
  }, [setState]);

  const onSortStatusChange = useCallback(async (sortStatus) => {
    const data = sortBy(state.recompensas, sortStatus.columnAccessor);

    setState({
      sortStatus: sortStatus,
      recompensas: sortStatus.direction === 'desc' ? data.reverse() : data
    });
  }, [state.recompensas, setState]);

  const onActionTable = useCallback(async (params, action) => {
    if (action === "ver") {
      if (params?.id) navigate(`${pathsRouter.pathAdmin}/${prefix}/${params?.id}`);
    } else if (action === "editar") {
      if (params?.id) navigate(`${pathsRouter.pathAdmin}/${prefix}/editar-recompensa/${params?.id}`);
    } else if (action === "eliminar") {
      dataSelectedModal.current = params;
      openModalEliminar();
    }
  }, [navigate, openModalEliminar]);

  const { columns, recompensasFilter } = useMemo(
    () => {
      const from = (state.page - 1) * state.page_size;
      const to = from + state.page_size;

      return {
        columns: [
          {
            accessor: 'name',
            title: "Nombre de recompensa",
            sortable: true,
          },
          {
            accessor: 'description',
            title: "Descripción",
            sortable: true,
          },
          {
            accessor: 'price',
            title: "Prosapesos",
            sortable: true,
          },
          {
            accessor: 'stock',
            title: "Inventario",
            sortable: true,
          },
          {
            accessor: 'actions',
            title: <Box mr={6}>Acciones</Box>,
            textAlign: 'center',
            // width: 300,
            render: (data) => (
              <Group gap={1} justify="center" wrap="nowrap">
                {hasPermissions({ permissions: user_store?.Permission?.rewardsPermissions, requiredPermissions: ['leer'] }) && (
                  <Button
                    size="sm"
                    variant="transparent"
                    color="#101010"
                    leftSection={<VerIcon height={12} width={15} />}
                    classNames={{ root: "px-3 border hover:border-gray-200 transition-all" }}
                    onClick={() => onActionTable(data, "ver")}
                  >
                    Ver
                  </Button>
                )}
                {hasPermissions({ permissions: user_store?.Permission?.rewardsPermissions, requiredPermissions: ['actualizar'] }) && (
                  <Button
                    size="sm"
                    variant="transparent"
                    color="#101010"
                    leftSection={<EditarIcon height={12} width={12} />}
                    classNames={{ root: "px-3 border hover:border-gray-200 transition-all" }}
                    onClick={() => onActionTable(data, "editar")}
                  >
                    Editar
                  </Button>
                )}
                {hasPermissions({ permissions: user_store?.Permission?.rewardsPermissions, requiredPermissions: ['eliminar'] }) && (
                  <Button
                    size="sm"
                    variant="transparent"
                    color="#101010"
                    leftSection={<EliminarIcon height={12} width={12} />}
                    classNames={{ root: "px-3 border hover:border-gray-200 transition-all" }}
                    onClick={() => onActionTable(data, "eliminar")}
                  >
                    Eliminar
                  </Button>
                )}
              </Group>
            ),
          },
        ],
        recompensasFilter: (state?.recompensas)?.slice(from, to)
      }
    },
    [state?.recompensas, state.page, state.page_size, user_store?.Permission?.rewardsPermissions, onActionTable],
  );

  return (
    <>
      <SEO
        title="Recompensas"
      />
      {hasPermissions({ permissions: user_store?.Permission?.rewardsPermissions, requiredPermissions: ['eliminar'] }) && (
        <EliminarModal
          title={"¿Estás seguro que deseas realizar esta acción?"}
          description={'Esta acción no se puede deshacer.'}
          acceptLabel={"Sí, eliminar"}
          opened={openedModalEliminar}
          onClose={closeModalEliminar}
          onReject={closeModalEliminar}
          onAccept={async () => {
            const params = dataSelectedModal.current;
            if (params?.id) await deleteReward(params?.id);
            closeModalEliminar();
            loadData();
          }}
        />
      )}
      <Grid>
        <div className='bg-bg h-full pb-4'>
          {state.loading ? (
            <Loading containerClassName="h-full" />
          ) : (
            <>
              <div className='bg-white px-6 lg:px-8 py-6 shadow-sm flex flex-wrap items-center justify-center md:justify-between gap-4'>
                <h1 className='flex-1 text-primary font-startup-semibold text-3xl text-center md:text-left'>Recompensas</h1>
                <div className='inline-flex flex-col sm:flex-row flex-wrap justify-center lg:justify-start gap-3'>
                  {hasPermissions({ permissions: user_store?.Permission?.rewardsPermissions, requiredPermissions: ['crear'] }) && (
                    <ButtonCustom
                      text="Nueva recompensa"
                      classNameButton="!font-startup border-primary bg-primary hover:!bg-primaryHover hover:!border-primaryHover"
                      iconLeft={
                        <AddIcon height={14} width={14} />
                      }
                      onClick={() => navigate(`${pathsRouter.pathAdmin}/${prefix}/crear-recompensa`)}
                    />
                  )}
                </div>
              </div>
              <div className='px-6 lg:px-8 container_table mt-6'>
                <DataTable
                  withTableBorder
                  withColumnBorders
                  borderRadius="md"
                  striped
                  highlightOnHover
                  horizontalSpacing="xs"
                  verticalSpacing="xs"
                  // height={window.innerHeight - 200}
                  sortStatus={state.sortStatus}
                  onSortStatusChange={onSortStatusChange}
                  recordsPerPageOptions={[10, 15, 20, 50, 100]}
                  recordsPerPageLabel={"Resultados por página"}
                  totalRecords={state.totalRecords || 0}
                  recordsPerPage={state.page_size}
                  page={state.page}
                  onPageChange={page => setState({ page })}
                  onRecordsPerPageChange={page => setState({ page_size: page })}
                  noRecordsText="No se encontraron resultados"
                  records={recompensasFilter}
                  columns={columns}
                />
              </div>
            </>
          )}
        </div>
      </Grid>
    </>
  );
};

export default Recompensas;