import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingOverlay, PasswordInput, Select, TextInput } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import SEO from '../../../components/SEO';
import { ButtonCustom } from '../../../components/Button';
import { useCustomState } from '../../../hooks/useCustomState';
import Grid from '../components/Grid';
import parseErrorMessage from '../../../utils/parseErrorMessage';
import { getPermisos } from '../../../api/admin/permisos';
import { createUsuario, getUsuarios, updateUsuario } from '../../../api/admin/usuarios';
import { isEmail, isEmpty } from '../../../utils';
import updateUserStore from '../../../utils/updateUserStore';
import pathsRouter from '../../../router/pathsRouter';
const prefix = "usuarios";

const ModificarUsuario = ({ isEditar, isCrear }) => {
  const isDisabled = (!isEditar && !isCrear);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user_store = useSelector(state => state?.admin?.auth?.user?.user || state?.admin?.auth?.user);
  const [state, setState] = useCustomState({
    loading: true,
    sending: false,
    nombre: "",
    apellidos: "",
    email: "",
    password: "",
    permiso_seleccionado: null,
    permisos: [],
  });

  useEffect(() => {
    loadData(id);
    // eslint-disable-next-line
  }, [id]);

  const loadData = useCallback(async (_id) => {
    const permisos = await getPermisos();
    const usuarios = (_id) ? await getUsuarios(_id) : null;
    const _usuario = (_id) ? usuarios?.data?.[0] : null;

    setState({
      loading: false,
      permisos: (permisos?.data)?.map(item => ({ label: item?.name, value: (item?.id)?.toString() })),
      nombre: _usuario?.firstName,
      apellidos: _usuario?.lastName,
      email: _usuario?.email,
      permiso_seleccionado: _usuario?.permissionID?.toString(),
    });
  }, [setState]);

  const message = useCallback((title, message) => {
    notifications.show({
      title: title,
      message: message,
    });
  }, []);

  const onSave = useCallback(async () => {
    if (isEditar || isCrear) {
      if (isEmpty(state.nombre)) return message(null, 'El nombre es obligatorio.');
      if (isEmpty(state.email)) return message(null, 'El correo electrónico es obligatorio.');
      if (!isEmail(state.email)) return message(null, 'El correo electrónico no es válido.');
      if (isEmpty(state.password)) return message(null, 'La contraseña es obligatoria.');
      if (isEmpty(state.permiso_seleccionado)) return message(null, 'Asigna un permiso para continuar.');

      try {
        setState({ sending: true });
        const _params_api = {
          firstName: state.nombre,
          lastName: state.apellidos,
          email: state.email,
          password: state.password,
          permissionID: state.permiso_seleccionado,
        };
        const response = isCrear ? await createUsuario(_params_api) : await updateUsuario(id, _params_api);
        setState({ sending: false });
        if (response?.status === true) {
          message(`Usuario ${isCrear ? "creado" : "actualizado"}`, `¡El usuario se ha ${isCrear ? "creado" : "actualizado"} exitosamente!`);
          updateUserStore(user_store, dispatch);
          navigate(`${pathsRouter.pathAdmin}/${prefix}`);
        } else {
          message('Ocurrio un problema', parseErrorMessage(response) || 'Lo sentimos, ocurrió un error con la solicitud. Por favor, inténtalo de nuevo más tarde.');
        }
      } catch (error) {
        setState({ sending: false });
        message('Ocurrio un problema', parseErrorMessage(error) || 'Lo sentimos, ocurrió un error inesperado. Por favor, inténtalo de nuevo más tarde.');
      }
    }

    // eslint-disable-next-line
  }, [isEditar, isCrear, id, user_store, dispatch, state, setState, navigate, message]);

  return (
    <>
      <SEO
        title={isEditar ? "Editar usuario" : (isCrear ? "Crear nuevo usuario" : "Ver usuario")}
      />
      <Grid>
        <div className='bg-bg h-full pb-4'>
          <div className='bg-white px-6 lg:px-8 py-6 shadow-sm flex flex-wrap items-center justify-center md:justify-between gap-4'>
            <h1 className='text-primary font-startup-semibold text-3xl text-center md:text-left'>{isEditar ? "Editar usuario" : (isCrear ? "Crear nuevo usuario" : "Ver usuario")}</h1>
          </div>
          <div className='px-6 lg:px-8'>
            <div className='relative flex flex-col bg-white mt-10 px-8 py-10 rounded-lg mx-auto md:mx-0 max-w-md w-full'>
              <LoadingOverlay visible={state.loading} zIndex={1000} overlayProps={{ radius: "sm", blur: 1 }} />
              <div autoComplete="off" aria-autocomplete='none' className='flex flex-col gap-5 mb-6'>
                <TextInput
                  size="md"
                  variant='filled'
                  label="Nombre"
                  placeholder="Escribe"
                  labelProps={{
                    ff: "Startup-Medium",
                    fw: 500,
                    mb: 5,
                    pl: 15
                  }}
                  classNames={{
                    label: "text-sm",
                    input: "h-auto py-1 text-sm",
                  }}
                  value={state?.nombre}
                  onChange={(event) => setState({ nombre: event.currentTarget.value })}
                  disabled={isDisabled}
                />
                <TextInput
                  size="md"
                  variant='filled'
                  label="Apellidos"
                  placeholder="Escribe"
                  labelProps={{
                    ff: "Startup-Medium",
                    fw: 500,
                    mb: 5,
                    pl: 15
                  }}
                  classNames={{
                    label: "text-sm",
                    input: "h-auto py-1 text-sm",
                  }}
                  value={state?.apellidos}
                  onChange={(event) => setState({ apellidos: event.currentTarget.value })}
                  disabled={isDisabled}
                />
                <TextInput
                  size="md"
                  variant='filled'
                  label="Correo electrónico"
                  placeholder="Escribe"
                  labelProps={{
                    ff: "Startup-Medium",
                    fw: 500,
                    mb: 5,
                    pl: 15
                  }}
                  classNames={{
                    label: "text-sm",
                    input: "h-auto py-1 text-sm",
                  }}
                  value={state?.email}
                  onChange={(event) => setState({ email: event.currentTarget.value })}
                  disabled={isDisabled}
                />
                <PasswordInput
                  size="md"
                  variant="filled"
                  label="Contraseña"
                  placeholder="Escribe"
                  labelProps={{
                    ff: "Startup-Medium",
                    fw: 500,
                    mb: 5,
                    pl: 15
                  }}
                  classNames={{
                    wrapper: "h-auto",
                    label: "text-sm",
                    input: "text-sm",
                    innerInput: "text-sm !bg-input",
                  }}
                  value={state?.password}
                  onChange={(event) => setState({ password: event.currentTarget.value })}
                  disabled={isDisabled}
                />
                <Select
                  size="md"
                  variant='filled'
                  label="Asignar permiso"
                  placeholder="Selecciona"
                  data={state.permisos}
                  labelProps={{
                    ff: "Startup-Medium",
                    fw: 500,
                    mb: 5,
                    pl: 15
                  }}
                  classNames={{
                    label: "text-sm",
                    input: "h-auto py-1 text-sm",
                  }}
                  value={state?.permiso_seleccionado}
                  onChange={(value) => setState({ permiso_seleccionado: value })}
                  disabled={isDisabled}
                />
              </div>
              <div className='flex flex-wrap justify-center sm:justify-end items-center gap-3 mt-14'>
                <ButtonCustom
                  text={"REGRESAR"}
                  classNameButton="border-stone-950 bg-stone-950 !text-neutral-200 hover:!bg-stone-900 hover:!border-stone-900 text-xs px-6"
                  onClick={() => navigate(`${pathsRouter.pathAdmin}/${prefix}`)}
                />
                {(isEditar || isCrear) && (
                  <ButtonCustom
                    text={isEditar ? "ACTUALIZAR" : "GUARDAR"}
                    classNameButton="border-primary bg-primary hover:!bg-primaryHover hover:!border-primaryHover text-xs px-6"
                    onClick={onSave}
                    loading={state.sending}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </>
  );
};

export default ModificarUsuario;