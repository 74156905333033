import qs from 'qs';
import axios from "..";
const prefix = "admin/colaboradores";

export const getColaboradores = async (id, filters, hasRewardsWallet=undefined) => {
  try {
    const params = qs.stringify({
      populate: ["program", "user_order"],
      filters: filters,
      hasRewardsWallet,
    });
    const endpoint = id ? `/${id}` : "";
    const response = await axios.get(`/${prefix}/${endpoint}?${params}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createColaborador = async (params) => {
  try {
    const _params = qs.stringify(params, { arrayFormat: 'brackets' });

    const response = await axios.post(`/${prefix}/nueva-clienta`, _params);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateColaborador = async (id, params) => {
  try {
    const _params = qs.stringify(params, { arrayFormat: 'brackets' });

    const response = await axios.put(`/${prefix}/${id}`, _params);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteColaborador = async (id) => {
  try {
    const response = await axios.delete(`/${prefix}/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const logoutColaborador = async (id) => {
  try {
    const response = await axios.post(`/${prefix}/cerrar-sesion`, { userID: id });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const exportColaborador = async (filters, _params) => {
  try {
    const params = qs.stringify({
      populate: ["program", "user_order"],
      filters: filters,
    });
    const _params = qs.stringify(params, { arrayFormat: 'brackets' });

    const response = await axios.post(`/${prefix}/exportar-xls?${params}`, _params, {
      responseType: 'blob'
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateRewardsWallet = async (id, rewardsWallet) => {
  try {
    const response = await axios.put(`/${prefix}/${id}`, {
      rewardsWallet: rewardsWallet
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
