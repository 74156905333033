import React from 'react';
import { Link } from 'react-router-dom';
// import { useSelector } from 'react-redux';
import SEO from '../../components/SEO';
// import GlobalContext from '../../contexts/GlobalContext';
import pathsRouter from '../../router/pathsRouter';

// import { ButtonCustom } from '../../components/Button';
import { Container, Icon, Title, Message } from './styles';

function NoEncontrado() {
  const canGoBack = window.history.state && window.history.state.idx > 0;
  // const { setModal } = useContext(GlobalContext);
  // const navigate = useNavigate();
  // const { isAuthenticated: isAuthenticatedUser } = useSelector(state => state.app.auth);
  // const { isAuthenticated: isAuthenticatedAdmin } = useSelector(state => state.admin.auth);
  const isAdmin = (document.location?.pathname)?.includes(`${pathsRouter.pathAdmin}/`);

  // const onLogin = useCallback(() => {
  //   if (isAdmin) {
  //     navigate(`${pathsRouter.pathAdmin}/login`);
  //   } else {
  //     setModal("login", navigate);
  //   }
  // }, [isAdmin, navigate, setModal]);

  return (
    <>
      <SEO title="Página no encontrada" />
      <div style={Container}>
        <Icon />
        <h1 style={Title}>Oops!</h1>
        <p style={Message}>
          Lo sentimos, la página que estás buscando no existe.
        </p>
        <Link
          to={canGoBack ? -1 : (isAdmin ? `${pathsRouter.pathAdmin}/` : "/")}
          className='font-startup-medium text-sm py-2 px-5 bg-primary hover:!bg-primaryHover border !border-primary text-white rounded-lg transition-all'
        >
          {canGoBack ? "Regresar" : "Ir a la página principal"}
        </Link>
      </div>
    </>
  );
}
export default NoEncontrado;