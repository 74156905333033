import { getProfile } from "../api/auth";
import { logout, updateUser } from "../redux/reducers/admin/authSlice";

async function updateUserStore(_userAdmin, dispatch) {
  if (!_userAdmin || !dispatch) return false;

  if (_userAdmin?.id && _userAdmin?.email) {
    try {
      const response = await getProfile({ id: _userAdmin?.id, email: _userAdmin?.email }, true);
      dispatch(updateUser({ user: response?.data }));
      return response;
    } catch (error) {
      if (error.response?.status === 401 || error.response?.status === 404) {
        dispatch(logout());
      }
      return { error };
    }
  } else {
    return { error: { mensaje: "La información del usuario es requerida" } };
  }
}

export default updateUserStore;