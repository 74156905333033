import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingOverlay, MultiSelect } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import SEO from '../../../components/SEO';
import { ButtonCustom } from '../../../components/Button';
import { useCustomState } from '../../../hooks/useCustomState';
import Grid from '../components/Grid';
import parseErrorMessage from '../../../utils/parseErrorMessage';
import { getPermisos, createPermisos, updatePermiso } from '../../../api/admin/permisos';
import { isEmpty } from '../../../utils';
import transformarPermisos from '../../../utils/transformarPermisos';
import updateUserStore from '../../../utils/updateUserStore';
import pathsRouter from '../../../router/pathsRouter';
const prefix = "permisos";

const ModificarPermiso = ({ isEditar, isCrear }) => {
  const isDisabled = (!isEditar && !isCrear);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user_store = useSelector(state => state?.admin?.auth?.user?.user || state?.admin?.auth?.user);
  const [state, setState] = useCustomState({
    loading: true,
    sending: false,
    nombre: "",
    permisos: null,
  });

  useEffect(() => {
    loadData(id);
    // eslint-disable-next-line
  }, [id]);

  const { dataOptions } = useMemo(() => {
    const dataOptions = [
      {
        label: "ver",
        value: "leer"
      },
      {
        label: "crear",
        value: "crear"
      },
      {
        label: "editar",
        value: "actualizar"
      },
      {
        label: "eliminar",
        value: "eliminar"
      },
    ];

    return { dataOptions };
  }, []);

  const loadData = useCallback(async (_id) => {
    const permisos = (_id) ? await getPermisos(_id) : null;
    const _permiso = (_id) ? permisos?.data : null;

    const permisos_default = _id ? transformarPermisos(_permiso) : null;

    setState({
      loading: false,
      nombre: _permiso?.name || "",
      permisos: permisos_default,
    });
  }, [setState]);

  const message = useCallback((title, message) => {
    notifications.show({
      title: title,
      message: message,
    });
  }, []);

  const onSave = useCallback(async () => {
    if (isEditar || isCrear) {
      if (isEmpty(state.nombre)) return message(null, 'El nombre es obligatorio.');

      try {
        setState({ sending: true });
        const _params_api = {
          name: state.nombre,
          ...transformarPermisos(state.permisos),
        };
        const response = isCrear ? await createPermisos(_params_api) : await updatePermiso(id, _params_api);
        setState({ sending: false });
        if (response?.status === true) {
          message(`Permiso ${isCrear ? "creado" : "actualizado"}`, `¡El permiso se ha ${isCrear ? "creado" : "actualizado"} exitosamente!`);
          updateUserStore(user_store, dispatch);
          navigate(`${pathsRouter.pathAdmin}/${prefix}`);
        } else {
          message('Ocurrio un problema', parseErrorMessage(response) || 'Lo sentimos, ocurrió un error con la solicitud. Por favor, inténtalo de nuevo más tarde.');
        }
      } catch (error) {
        setState({ sending: false });
        message('Ocurrio un problema', parseErrorMessage(error) || 'Lo sentimos, ocurrió un error inesperado. Por favor, inténtalo de nuevo más tarde.');
      }
    }

    // eslint-disable-next-line
  }, [isEditar, isCrear, id, user_store, dispatch, state, setState, navigate, message]);

  const RolesDesign = useCallback(({ name, dataOptionsCustom, value, onChange }) => (
    <div className='flex flex-col gap-2 w-full'>
      <MultiSelect
        label={name}
        labelProps={{ mb: 5 }}
        classNames={{
          root: "max-w-[420px] w-full",
          inputField: "!ring-0 !outline-none",
          option: "capitalize",
          pill: "capitalize",
        }}
        rightSectionPointerEvents='none'
        variant="default"
        data={dataOptionsCustom || dataOptions}
        placeholder='Seleccionar permisos'
        value={value || []}
        onChange={onChange}
        clearable
        disabled={isDisabled}
      />
    </div>
  ), [dataOptions, isDisabled]);

  return (
    <>
      <SEO
        title={isEditar ? "Editar permiso" : (isCrear ? "Crear nuevo permiso" : "Ver permiso")}
      />
      <Grid>
        <div className='bg-bg h-full pb-4'>
          <div className='bg-white px-6 lg:px-8 py-6 shadow-sm flex flex-wrap items-center justify-center md:justify-between gap-4'>
            <h1 className='text-primary font-startup-semibold text-3xl text-center md:text-left'>{isEditar ? "Editar permiso" : (isCrear ? "Crear nuevo permiso" : "Ver permiso")}</h1>
          </div>
          <div className='px-6 lg:px-8'>
            <div className='relative flex flex-col bg-white mt-10 px-8 py-10 rounded-lg mx-auto md:mx-0 max-w-md w-full'>
              <LoadingOverlay visible={state.loading} zIndex={1000} overlayProps={{ radius: "sm", blur: 1 }} />
              <div className='mb-6'>
                <input
                  type="text"
                  placeholder='Nombre del permiso'
                  className="bg-bg border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                  value={state?.nombre}
                  onChange={(event) => setState({ nombre: event.target.value })}
                  disabled={isDisabled}
                />
              </div>
              <p className='font-startup-medium text-primary mb-3 text-xl text-center'>Asignar permisos:</p>
              <div className='flex flex-col gap-6 w-full'>
                <RolesDesign
                  name="Usuarios"
                  onChange={value => setState({ usersPermissions: value }, "permisos")}
                  value={state.permisos?.usersPermissions}
                />
                <RolesDesign
                  name="Permisos"
                  onChange={value => setState({ permissionsPermissions: value }, "permisos")}
                  value={state.permisos?.permissionsPermissions}
                />
                <RolesDesign
                  name="Colaboradores"
                  dataOptionsCustom={dataOptions.filter(item => ["leer"].includes(item?.value))}
                  onChange={value => setState({ collaboratorsPermissions: value }, "permisos")}
                  value={state.permisos?.collaboratorsPermissions}
                />
                <RolesDesign
                  name="Acciones"
                  onChange={value => setState({ actionsPermissions: value }, "permisos")}
                  value={state.permisos?.actionsPermissions}
                />
                <RolesDesign
                  name="Recompensas"
                  onChange={value => setState({ rewardsPermissions: value }, "permisos")}
                  value={state.permisos?.rewardsPermissions}
                />
                <RolesDesign
                  name="Reportes"
                  dataOptionsCustom={dataOptions.filter(item => ["leer", "actualizar"].includes(item?.value))}
                  onChange={value => setState({ reportsPermissions: value }, "permisos")}
                  value={state.permisos?.reportsPermissions}
                />
                <RolesDesign
                  name="Avisos"
                  onChange={value => setState({ noticesPermissions: value }, "permisos")}
                  value={state.permisos?.noticesPermissions}
                />
              </div>
              <div className='flex flex-wrap justify-center sm:justify-end items-center gap-3 mt-14'>
                <ButtonCustom
                  text={"REGRESAR"}
                  classNameButton="border-stone-950 bg-stone-950 !text-neutral-200 hover:!bg-stone-900 hover:!border-stone-900 text-xs px-6"
                  onClick={() => navigate(`${pathsRouter.pathAdmin}/${prefix}`)}
                />
                {(isEditar || isCrear) && (
                  <ButtonCustom
                    text={isEditar ? "GUARDAR" : "CREAR"}
                    classNameButton="border-primary bg-primary hover:!bg-primaryHover hover:!border-primaryHover text-xs px-6"
                    onClick={onSave}
                    loading={state.sending}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </>
  );
};

export default ModificarPermiso;