import React, { useCallback, useEffect } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Select } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import SEO from '../../../components/SEO';
import { ButtonCustom } from '../../../components/Button';
import { useCustomState } from '../../../hooks/useCustomState';
import Grid from '../components/Grid';
import { isEmpty } from '../../../utils';
import pathsRouter from '../../../router/pathsRouter';
import { hasPermissions } from '../../../router/RequireAuth';
import {
  getRequestRewards,
  updateStatusRequestReward,
} from '../../../api/admin/reportRequestRewards';
const prefix = "reportes_recompensas";

const DetallesRecompensa = () => {
  const navigate = useNavigate();
  const {id} = useParams();
  const user_store = useSelector(state => state?.admin?.auth?.user?.user || state?.admin?.auth?.user);
  const [state, setState] = useCustomState({
    loading: false,
    sending: false,
    status: "pendiente",
    name: '',
    reward: '',
    price_reward: 0,
  });

  const loadData = useCallback(async () => {
    const data = await getRequestRewards(id);
    const dataMap = data.data.map(
        item => {
          return {
            name: item.User?.Collaborator?.name,
            reward: item?.Reward?.name,
            price_reward: item?.Reward?.price * parseInt(item?.answer),
            status: item?.status,
          };
        },
    );
    if (dataMap.length > 0) {
      setState({
        name: dataMap[0]?.name,
        reward: dataMap[0]?.reward,
        price_reward: dataMap[0]?.price_reward,
        status: dataMap[0]?.status,
        loading: false,
      });
    }
  }, [id, setState]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const message = useCallback((title, message) => {
    notifications.show({
      title: title,
      message: message,
    });
  }, []);

  const onCreate = useCallback(async () => {
    if (isEmpty(state.status)) {
      return message(null, 'Selecciona el estatus para continuar.');
    }
    updateStatusRequestReward(id, state?.status).then(
        response => navigate(`${pathsRouter.pathAdmin}/${prefix}`)
    ).catch(error => console.error(error));
  }, [id, message, state]);

  return (
    <>
      <SEO
        title={"Estatus de recompensa"}
      />
      <Grid>
        <div className='bg-bg h-full pb-4'>
          <div className='bg-white px-6 lg:px-8 py-6 shadow-sm flex flex-wrap items-center justify-center md:justify-between gap-4'>
            <h1 className='text-primary font-startup-semibold text-3xl text-center md:text-left'>{"Estatus de recompensa"}</h1>
          </div>
          <div className='px-6 lg:px-8'>
            <div className='flex flex-col bg-white mt-10 px-8 py-10 rounded-lg mx-auto md:mx-0 max-w-md w-full'>
              <div className='flex flex-col gap-5 mb-6'>
                <div className='flex flex-col gap-2'>
                  <p className='text-sm font-startup-medium'>Nombre de colaborador</p>
                  <Box className='bg-input p-2.5 rounded-md'>
                    <p className='text-sm'>{state?.name}</p>
                  </Box>
                </div>
                <div className='flex flex-col gap-2'>
                  <p className='text-sm font-startup-medium'>Recompensa</p>
                  <Box className='bg-input p-2.5 rounded-md'>
                    <p className='text-sm'>{state?.reward}</p>
                  </Box>
                </div>
                <div className='flex flex-col gap-2'>
                  <p className='text-sm font-startup-medium'>Prosapesos</p>
                  <Box className='bg-input p-2.5 rounded-md'>
                    <p className='text-sm'>{state?.price_reward}</p>
                  </Box>
                </div>
                <Select
                  size="md"
                  variant='filled'
                  label="Estatus"
                  placeholder="Selecciona"
                  data={['pendiente', 'entregada']}
                  labelProps={{
                    ff: "Startup-Medium",
                    fw: 500,
                    mb: 5,
                    // pl: 15
                  }}
                  classNames={{
                    label: "text-sm",
                    input: "h-auto py-1 text-sm capitalize",
                    option: "capitalize",
                  }}
                  value={state.status}
                  onChange={(value) => setState({ status: value })}
                />
              </div>
              <div className='flex flex-wrap justify-center sm:justify-end items-center gap-3 mt-14'>
                <ButtonCustom
                  text={"REGRESAR"}
                  classNameButton="border-stone-950 bg-stone-950 !text-neutral-200 hover:!bg-stone-900 hover:!border-stone-900 text-xs px-6"
                  onClick={() => navigate(`${pathsRouter.pathAdmin}/${prefix}`)}
                />
                {hasPermissions({ permissions: user_store?.Permission?.reportsPermissions, requiredPermissions: ['actualizar'] }) && (
                  <ButtonCustom
                    text={"GUARDAR"}
                    classNameButton="border-primary bg-primary hover:!bg-primaryHover hover:!border-primaryHover text-xs px-6"
                    onClick={onCreate}
                    loading={state.sending}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </>
  );
};

export default DetallesRecompensa;