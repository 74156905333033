import React, {useCallback, useEffect, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import sortBy from 'lodash/sortBy';
import {DataTable} from 'mantine-datatable';
import {notifications} from '@mantine/notifications';
import {Box, Button, Group} from '@mantine/core';
import {useDisclosure} from '@mantine/hooks';
import {ReactComponent as AddIcon} from '../../../assets/icons/add-icon.svg';
import {
  ReactComponent as VerIcon,
} from '../../../assets/icons/accion01-ver.svg';
import {
  ReactComponent as EditarIcon,
} from '../../../assets/icons/accion02-editar.svg';
import SEO from '../../../components/SEO';
import {ButtonCustom} from '../../../components/Button';
import Loading from '../../../components/Loading';
import AsignarProsapesos from '../../../components/Modal/AsignarProsapesos';
import {useCustomState} from '../../../hooks/useCustomState';
import Grid from '../components/Grid';
import pathsRouter from '../../../router/pathsRouter';
import {hasPermissions} from '../../../router/RequireAuth';
import {
  assignRewardsWallet,
  getReportParticipation,
} from '../../../api/admin/reportParticipation';

const prefix = 'reportes_participacion';

const ReporteParticipacion = () => {
  const [
    openedModalProsapesos, {
      close: closeModalProsapesos,
      open: openModalProsapesos,
    }] = useDisclosure(false);
  const navigate = useNavigate();
  const user_store = useSelector(
      state => state?.admin?.auth?.user?.user || state?.admin?.auth?.user);
  const [state, setState] = useCustomState({
    sending: false,
    loading: true,
    isMultiple: false,
    page: 1,
    page_size: 10,
    totalRecords: 0,
    sortStatus: {},
    rowSelection: [],
    rowSelected: null,
    data: [],
  });

  const loadData = useCallback(async () => {
    setState({ loading: true });
    const data = await getReportParticipation();
    const dataMap = data.data.map(
        item => {
          return {
            id: item.id,
            name_colaborator: item.User?.Collaborator?.name,
            date_participation: new Date(item?.createdAt).toLocaleDateString(
                'es-mx', {year: 'numeric', month: 'long', day: 'numeric'}),
            apartment: item?.CatalogDepartment?.name,
            action: item?.Action?.name,
            answer_text: item?.answer,
            price_reward: item?.priceReward,
          };
        },
    );
    setState({
      data: dataMap,
      totalRecords: dataMap?.length,
      loading: false,
    });
  }, [setState]);

  const message = useCallback((title, message) => {
    notifications.show({
      title: title,
      message: message,
    });
  }, []);

  const onSortStatusChange = useCallback(async (sortStatus) => {
    const data = sortBy(state.data, sortStatus.columnAccessor);

    setState({
      sortStatus: sortStatus,
      data: sortStatus.direction === 'desc' ? data.reverse() : data,
    });
  }, [state.data, setState]);

  const onActionTable = useCallback(async (params, action) => {
    if (action === 'ver') {
      if (params?.id) navigate(
          `${pathsRouter.pathAdmin}/${prefix}/detalles/${params?.id}`);
    } else if (action === 'editar') {
      if (params?.id) {
        // Mostrar modal para asignar prosapesos.
        openModalProsapesos();
        setState({isMultiple: false, rowSelected: params});
      }
    }
  }, [openModalProsapesos, navigate, setState]);

  const {columns, dataFilter} = useMemo(
      () => {
        const from = (state.page - 1) * state.page_size;
        const to = from + state.page_size;

        return {
          columns: [
            {
              accessor: 'name_colaborator',
              title: 'Nombre de colaborador',
              sortable: true,
            },
            {
              accessor: 'date_participation',
              title: 'Fecha de participación',
              sortable: true,
            },
            {
              accessor: 'apartment',
              title: 'Departamento',
              sortable: true,
            },
            {
              accessor: 'action',
              title: 'Acción',
              sortable: true,
            },
            {
              accessor: 'answer_text',
              title: 'Respuesta de participación (texto)',
              sortable: true,
            },
            {
              accessor: 'price_reward',
              title: 'Prosapesos asignados',
              sortable: true,
            },
            {
              accessor: 'actions',
              title: <Box mr={6}>Acciones</Box>,
              textAlign: 'center',
              width: 400,
              render: (data) => (
                  <Group gap={1} justify="center" wrap="nowrap">
                    {hasPermissions({
                      permissions: user_store?.Permission?.reportsPermissions,
                      requiredPermissions: ['leer'],
                    }) && (
                        <Button
                            size="sm"
                            variant="transparent"
                            color="#101010"
                            leftSection={<VerIcon height={12} width={15}/>}
                            classNames={{root: 'px-3 border hover:border-gray-200 transition-all'}}
                            onClick={() => onActionTable(data, 'ver')}
                        >
                          Ver participación
                        </Button>
                    )}
                    {hasPermissions({
                      permissions: user_store?.Permission?.reportsPermissions,
                      requiredPermissions: ['actualizar'],
                    }) && (
                        <Button
                            size="sm"
                            variant="transparent"
                            color="#101010"
                            leftSection={<EditarIcon height={12} width={12}/>}
                            classNames={{root: 'px-3 border hover:border-gray-200 transition-all'}}
                            onClick={() => onActionTable(data, 'editar')}
                        >
                          Asignar prosapesos
                        </Button>
                    )}
                  </Group>
              ),
            },
          ],
          dataFilter: (state?.data)?.slice(from, to),
        };
      },
      [
        state?.data,
        state.page,
        state.page_size,
        user_store?.Permission?.reportsPermissions,
        onActionTable],
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  const onAccept= async (rewardsWallet) => {
    if (state?.rowSelected) {
      assignRewardsWallet(state?.rowSelected.id, parseFloat(rewardsWallet))
      .then(response => {
        if (response.status) {
          closeModalProsapesos();
          loadData();
        }
      })
      .catch(error => console.log(error));
    }
  };

  return (
      <>
        <SEO
            title="Reporte de participación"
        />
        {hasPermissions({
          permissions: user_store?.Permission?.reportsPermissions,
          requiredPermissions: ['actualizar'],
        }) && (
            <AsignarProsapesos
                title={'Asignar prosapesos a los colaboradores:'}
                acceptLabel={'Asignar'}
                opened={openedModalProsapesos}
                onClose={closeModalProsapesos}
                onReject={closeModalProsapesos}
                data={{
                  rowSelection: state.isMultiple
                      ? state.rowSelection
                      : [state.rowSelected],
                }}
                onAccept={onAccept}
            />
        )}
        <Grid>
          <div className="bg-bg h-full pb-4">
            {state.loading ? (
                <Loading containerClassName="h-full"/>
            ) : (
                <>
                  <div
                      className="bg-white px-6 lg:px-8 py-6 shadow-sm flex flex-wrap items-center justify-center md:justify-between gap-4">
                    <h1 className="flex-1 text-primary font-startup-semibold text-3xl text-center md:text-left">Reporte
                      de participación</h1>
                    <div
                        className="inline-flex flex-col sm:flex-row flex-wrap justify-center lg:justify-start gap-3">
                      {hasPermissions({
                        permissions: user_store?.Permission?.reportsPermissions,
                        requiredPermissions: ['actualizar'],
                      }) && (
                          <ButtonCustom
                              text="Asignar prosapesos"
                              classNameButton="!font-startup border-primary bg-primary hover:!bg-primaryHover hover:!border-primaryHover"
                              iconLeft={
                                <AddIcon height={14} width={14}/>
                              }
                              onClick={() => {
                                if (state.rowSelection?.length <=
                                    0) return message(
                                    'Selecciona uno o más colaboradores para continuar');
                                openModalProsapesos();
                                setState({isMultiple: true});
                              }}
                          />
                      )}
                    </div>
                  </div>
                  <div className="px-6 lg:px-8 container_table mt-6">
                    <DataTable
                        withTableBorder
                        withColumnBorders
                        borderRadius="md"
                        striped
                        highlightOnHover
                        horizontalSpacing="xs"
                        verticalSpacing="xs"
                        // height={window.innerHeight - 200}
                        sortStatus={state.sortStatus}
                        onSortStatusChange={onSortStatusChange}
                        recordsPerPageOptions={[10, 15, 20, 50, 100]}
                        recordsPerPageLabel={'Resultados por página'}
                        totalRecords={state.totalRecords || 0}
                        recordsPerPage={state.page_size}
                        page={state.page}
                        onPageChange={page => setState({page})}
                        onRecordsPerPageChange={page => setState(
                            {page_size: page})}
                        noRecordsText="No se encontraron resultados"
                        selectedRecords={state.rowSelection}
                        onSelectedRecordsChange={value => setState(
                            {rowSelection: value})}
                        records={dataFilter}
                        columns={columns}
                    />
                  </div>
                </>
            )}
          </div>
        </Grid>
      </>
  );
};

export default ReporteParticipacion;