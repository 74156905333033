import React, { useCallback, useEffect } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ActionIcon, Box, Flex, Image, SimpleGrid } from '@mantine/core';
import { ReactComponent as PlayIcon } from '../../../assets/icons/play-icon.svg';
import SEO from '../../../components/SEO';
import { ButtonCustom } from '../../../components/Button';
import { useCustomState } from '../../../hooks/useCustomState';
import Grid from '../components/Grid';
import pathsRouter from '../../../router/pathsRouter';
import { hasPermissions } from '../../../router/RequireAuth';
import {
  assignRewardsWallet,
  getReportParticipation,
} from '../../../api/admin/reportParticipation';
import {useDisclosure} from '@mantine/hooks';
import AsignarProsapesos from '../../../components/Modal/AsignarProsapesos';
const prefix = "reportes_participacion";

const DetallesParticipacion = () => {
  const navigate = useNavigate();
  const {id} = useParams();
  const user_store = useSelector(state => state?.admin?.auth?.user?.user || state?.admin?.auth?.user);
  const [state, setState] = useCustomState({
    sending: false,
    playing: false,
  });
  const [
    openedModalProsapesos, {
      close: closeModalProsapesos,
      open: openModalProsapesos,
    }] = useDisclosure(false);

  const loadData = useCallback(async () => {
    const data = await getReportParticipation(id);
    const dataMap = data.data.map(
        item => {
          return {
            name: item.User?.Collaborator?.name,
            action: item?.Action?.name,
            department: item?.CatalogDepartment?.name,
            createdAt: new Date(item?.createdAt).toLocaleDateString(
                'es-mx', {year: 'numeric', month: 'long', day: 'numeric'}),
            participation: item?.answer,
            price: item?.Action?.price,
            image: item?.image,
            audio: item?.audio,
          };
        },
    );
    if (dataMap.length > 0) {
      setState({
        name: dataMap[0]?.name,
        action: dataMap[0]?.action,
        department: dataMap[0]?.department,
        createdAt: dataMap[0]?.createdAt,
        participation: dataMap[0]?.participation,
        price: dataMap[0]?.price,
        image: dataMap[0]?.image,
        audio: dataMap[0]?.audio,
        loading: false,
      });
    }
  }, [id, setState]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const onCreate = useCallback(async () => {
    openModalProsapesos();
    // eslint-disable-next-line
  }, []);

  const onAccept= async (rewardsWallet) => {
    assignRewardsWallet(id, parseFloat(rewardsWallet))
    .then(response => {
      if (response.status) {
        closeModalProsapesos();
        loadData();
        navigate(`${pathsRouter.pathAdmin}/${prefix}`);
      }
    })
    .catch(error => console.log(error));
  };

  return (
    <>
      <SEO
        title={"Detalle de participación"}
      />
      {hasPermissions({
        permissions: user_store?.Permission?.reportsPermissions,
        requiredPermissions: ['actualizar'],
      }) && (
          <AsignarProsapesos
              title={'Asignar prosapesos a los colaboradores:'}
              acceptLabel={'Asignar'}
              opened={openedModalProsapesos}
              onClose={closeModalProsapesos}
              onReject={closeModalProsapesos}
              data={{
                rowSelection: state.isMultiple
                    ? state.rowSelection
                    : [state.rowSelected],
              }}
              onAccept={onAccept}
          />
      )}
      <Grid>
        <div className='bg-bg h-full pb-4'>
          <div className='bg-white px-6 lg:px-8 py-6 shadow-sm flex flex-wrap items-center justify-center md:justify-between gap-4'>
            <h1 className='text-primary font-startup-semibold text-3xl text-center md:text-left'>{"Detalle de participación"}</h1>
          </div>
          <div className='px-6 lg:px-8'>
            <div className='flex flex-col bg-white mt-10 px-8 py-10 rounded-lg mx-auto md:mx-0 w-full'>
              <SimpleGrid
                cols={{ base: 1, sm: 2, lg: 2 }}
                spacing={{ base: 10, sm: 'xl' }}
                verticalSpacing={{ base: 'md', sm: 'xl' }}
              >
                <div className='flex flex-col gap-5 mb-6'>
                  <div className='flex flex-col gap-2'>
                    <p className='text-sm font-startup-medium'>Nombre de colaborador</p>
                    <Box className='bg-input p-2.5 rounded-md'>
                      <p className='text-sm'>{state?.name}</p>
                    </Box>
                  </div>
                  <div className='flex flex-col gap-2'>
                    <p className='text-sm font-startup-medium'>Departamento</p>
                    <Box className='bg-input p-2.5 rounded-md'>
                      <p className='text-sm'>{state?.department}</p>
                    </Box>
                  </div>
                  <div className='flex flex-col gap-2'>
                    <p className='text-sm font-startup-medium'>Participación</p>
                    <Box className='bg-input p-2.5 rounded-md'>
                      <p className='text-sm'>{state?.participation}</p>
                    </Box>
                  </div>
                </div>
                <div className='flex flex-col gap-5 mb-6'>
                  <div className='flex flex-col gap-2'>
                    <p className='text-sm font-startup-medium'>Acción</p>
                    <Box className='bg-input p-2.5 rounded-md'>
                      <p className='text-sm'>{state?.action}</p>
                    </Box>
                  </div>
                  <div className='flex flex-col gap-2'>
                    <p className='text-sm font-startup-medium'>Fecha de participación</p>
                    <Box className='bg-input p-2.5 rounded-md'>
                      <p className='text-sm'>{state?.createdAt}</p>
                    </Box>
                  </div>
                  <div className='flex flex-col gap-2'>
                    <p className='text-sm font-startup-medium'>Prosapesos</p>
                    <Box className='bg-input p-2.5 rounded-md'>
                      <p className='text-sm'>{state?.price}</p>
                    </Box>
                  </div>
                </div>
              </SimpleGrid>
              <div className='flex flex-col gap-5 mt-10'>
                <Flex wrap="wrap" gap={30}>
                  <Box className='relative h-[110px] w-[190px] mb-4' radius="md">
                    <p className='text-sm font-startup-medium mb-2'>Imagen</p>
                    <a href={state?.image ? state?.image : 'https://placehold.co/600x300?text=Placeholder'} rel="noreferrer" target='_blank'>
                      <Image
                        className='bg-[#dddddd] w-full h-full'
                        radius="md"
                        fit="contain"
                        alt="Preview portada"
                        src={state?.image ? state?.image : 'https://placehold.co/600x300?text=Placeholder'}
                        fallbackSrc="https://placehold.co/600x300?text=Placeholder"
                      />
                    </a>
                  </Box>
                  <Box className='relative h-[110px] w-[190px] mb-4' radius="md">
                    <p className='text-sm font-startup-medium mb-2'>Audio</p>
                    <Box
                      className={'relative flex items-center bg-[#dddddd] rounded-md h-full w-full'}
                    >
                      <div className='flex items-center justify-center absolute p-2 h-full w-full select-none'>
                        {state.playing ? (
                          <audio controls autoPlay controlsList="nodownload">
                            <source src={state?.audio} type="audio/mpeg" />
                          </audio>
                        ) : (
                          <ActionIcon variant="transparent" size={64} aria-label="Play" onClick={() => setState({ playing: true })}>
                            <PlayIcon height={64} width={64} />
                          </ActionIcon>
                        )}
                      </div>
                    </Box>
                  </Box>
                </Flex>
              </div>
              <div className='flex flex-wrap justify-center sm:justify-end items-center gap-3 mt-14'>
                <ButtonCustom
                  text={"REGRESAR"}
                  classNameButton="border-stone-950 bg-stone-950 !text-neutral-200 hover:!bg-stone-900 hover:!border-stone-900 text-xs px-6"
                  onClick={() => navigate(`${pathsRouter.pathAdmin}/${prefix}`)}
                />
                {hasPermissions({ permissions: user_store?.Permission?.reportsPermissions, requiredPermissions: ['actualizar'] }) && (
                  <ButtonCustom
                    text={"ASIGNAR PROSAPESOS"}
                    classNameButton="border-primary bg-primary hover:!bg-primaryHover hover:!border-primaryHover text-xs px-6"
                    onClick={onCreate}
                    loading={state.sending}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </>
  );
};

export default DetallesParticipacion;