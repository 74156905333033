import { createTheme } from '@mantine/core';

const theme = createTheme({
  fontFamily: 'Startup-Regular, Montserrat, sans-serif',
  cursorType: 'pointer',
  defaultRadius: 'md',
  colorScheme: "light",
  primaryColor: "blue"
});

export default theme;