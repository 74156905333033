const getPreviewImage = (file, onImageChange) => {
  if (file) {
    const reader = new FileReader();

    reader.onload = () => {
      const imageSrc = reader.result;
      onImageChange(imageSrc);
    };

    reader.readAsDataURL(file);
  }
};

export default getPreviewImage;