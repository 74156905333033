import { Button, Flex, Modal, NumberInput, Stack } from '@mantine/core';
import { useCustomState } from '../../hooks/useCustomState';
import { useMemo } from 'react';

export default function AsignarProsapesos({ opened, onClose, title, hideColaborators, data, rejectLabel, acceptLabel, onReject, onAccept, collaboratorID }) {
  const [state, setState] = useCustomState({
    cantidad: null
  });

  const { name_colaborator } = useMemo(() => {
    const name_colaborator = data?.rowSelection?.map(item => item?.name_colaborator)?.join(", ");

    return { name_colaborator };
  }, [data?.rowSelection]);

  const onClick = async () => {
    onAccept(state?.cantidad);
  };

  return (
    <Modal classNames={{ content: "shadow" }} centered opened={opened} onClose={onClose} size="sm" withCloseButton={false}>
      <div className='flex flex-col items-center justify-center gap-2 max-w-[320px] mx-auto px-4 py-2'>
        {title && (<h1 className="font-startup-bold text-lg text-center">{title}</h1>)}
        {!hideColaborators && <p className="font-startup-medium text-muted text-gray-700 text-center">{name_colaborator}</p>}
        <Stack className='w-full' gap={4} mt={20}>
          <NumberInput
            size="md"
            variant='filled'
            placeholder="0"
            clampBehavior="strict"
            min={1}
            max={9999}
            classNames={{
              label: "text-sm",
              input: "h-auto py-1 text-sm",
            }}
            value={state?.cantidad}
            onChange={(value) => setState({ cantidad: value })}
          />
        </Stack>
        <Flex className='w-full' gap={16} mt={30}>
          <Button
            variant="filled"
            className='bg-white border border-gray-300 hover:bg-gray-100 text-sm text-text h-auto py-2.5 transition-all'
            fullWidth
            onClick={onReject}
          >
            {rejectLabel || "Cancelar"}
          </Button>
          <Button
            variant="filled"
            className='bg-primary border border-primary hover:bg-primaryHover text-sm text-white h-auto py-2.5 transition-all'
            fullWidth
            onClick={onClick}
          >
            {acceptLabel || "Eliminar"}
          </Button>
        </Flex>
      </div>
    </Modal>
  );
}