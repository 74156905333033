function transformarPermisos(permiso, toJson) {
  if (!permiso) {
    return null;
  }

  const permisosTransformados = {};

  for (const key in permiso) {
    if (key === 'id' || key === 'name' || key === 'updatedAt' || key === 'createdAt' || key === 'deletedAt') {
      continue; // Ignorar propiedades no relacionadas con permisos
    }

    const permisos = permiso[key];

    if (Array.isArray(permisos)) {
      const permisosObjeto = {};

      for (const accion of permisos) {
        permisosObjeto[accion] = 'true';
      }

      permisosTransformados[key] = toJson ? JSON.stringify(permisosObjeto) : permisosObjeto;
    } else {
      const permisosArray = [];

      for (const accion in permisos) {
        if (permisos[accion] === true || permisos[accion] === 'true') {
          permisosArray.push(accion);
        }
      }

      permisosTransformados[key] = permisosArray.length > 0 ? (toJson ? JSON.stringify(permisosArray) : permisosArray) : null;
    }
  }

  return permisosTransformados;
}

export default transformarPermisos;