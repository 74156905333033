export const prefix = "prosazon-app_"; // Usado para LocalStorage Service & Redux Persist

// En desarrollo (dev) - Para cambiar al entorno de producción usar "true"
const isProduction = true;

// Configuración de las URL según el entorno
export const API_BASE_URL = isProduction
  ? 'https://api.prosazon.metodika.com.mx'
  : 'http://localhost:3001'; // http://localhost:3001 o https://reqres.in/api

export const WEB_BASE_URL = isProduction
  ? 'https://admin.prosazon.metodika.com.mx'
  : 'http://localhost:3000';

export const apiKeys = {
  captcha: "",
}

export const externalLinks = {
}