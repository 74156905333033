import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import sortBy from 'lodash/sortBy';
import { DataTable } from 'mantine-datatable';
import { Box, Button, Group } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { ReactComponent as EditarIcon } from '../../../assets/icons/accion02-editar.svg';
import SEO from '../../../components/SEO';
import Loading from '../../../components/Loading';
import AsignarProsapesos from '../../../components/Modal/AsignarProsapesos';
import { useCustomState } from '../../../hooks/useCustomState';
import Grid from '../components/Grid';
import { hasPermissions } from '../../../router/RequireAuth';
import {
  getColaboradores,
  updateRewardsWallet,
} from '../../../api/admin/colaboradores';

const ReporteProsapesos = () => {
  const [openedModalProsapesos, { close: closeModalProsapesos, open: openModalProsapesos }] = useDisclosure(false);
  const user_store = useSelector(state => state?.admin?.auth?.user?.user || state?.admin?.auth?.user);
  const [state, setState] = useCustomState({
    sending: false,
    loading: true,
    page: 1,
    page_size: 10,
    totalRecords: 0,
    sortStatus: {},
    rowSelected: null,
    data: [],
  });

  const loadData = useCallback(async () => {
    const data = await getColaboradores(null, [], true);
    const dataMap = data.data.map(
        item => {
          return {
            id: item.id,
            name_colaborator: item.name,
            apartment: item?.department,
            wallet: item?.rewardsWallet,
          };
        },
    );

    setState({
      data: dataMap,
      totalRecords: (dataMap)?.length,
      loading: false,
    });
  }, [setState]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const onAccept= async (rewardsWallet) => {
    if (state?.rowSelected) {
      updateRewardsWallet(state?.rowSelected.id, parseFloat(rewardsWallet))
      .then(response => {
        if (response.status) {
          closeModalProsapesos();
          loadData();
        }
      })
      .catch(error => console.log(error));
    }
  };

  const onSortStatusChange = useCallback(async (sortStatus) => {
    const data = sortBy(state.data, sortStatus.columnAccessor);

    setState({
      sortStatus: sortStatus,
      data: sortStatus.direction === 'desc' ? data.reverse() : data
    });
  }, [state.data, setState]);

  const onActionTable = useCallback(async (params, action) => {
    if (action === "editar") {
      if (params?.id) {
        // Mostrar modal para asignar prosapesos.
        openModalProsapesos();
        setState({ rowSelected: params });
      }
    }
  }, [openModalProsapesos, setState]);

  const { columns, dataFilter } = useMemo(
    () => {
      const from = (state.page - 1) * state.page_size;
      const to = from + state.page_size;

      return {
        columns: [
          {
            accessor: 'name_colaborator',
            title: "Nombre de colaborador",
            sortable: true,
          },
          {
            accessor: 'apartment',
            title: "Departamento",
            sortable: true,
          },
          {
            accessor: 'wallet',
            title: "Prosapesos",
            sortable: true,
          },
          {
            accessor: 'actions',
            title: <Box mr={6}>Acciones</Box>,
            textAlign: 'center',
            // width: 240,
            render: (data) => (
              <Group gap={1} justify="center" wrap="nowrap">
                {hasPermissions({ permissions: user_store?.Permission?.reportsPermissions, requiredPermissions: ['actualizar'] }) && (
                  <Button
                    size="sm"
                    variant="transparent"
                    color="#fff"
                    leftSection={<EditarIcon fill={"#fff"} height={12} width={12} />}
                    classNames={{ root: "px-3 bg-primary hover:bg-primaryHover transition-all" }}
                    onClick={() => onActionTable(data, "editar")}
                  >
                    Modificar prosapesos
                  </Button>
                )}
              </Group>
            ),
          },
        ],
        dataFilter: (state?.data)?.slice(from, to)
      }
    },
    [state?.data, state.page, state.page_size, user_store?.Permission?.reportsPermissions, onActionTable],
  );

  return (
    <>
      <SEO
        title="Reporte de prosapesos"
      />
      {hasPermissions({ permissions: user_store?.Permission?.reportsPermissions, requiredPermissions: ['actualizar'] }) && (
        <AsignarProsapesos
          title={"Modificar prosapesos"}
          acceptLabel={"Actualizar"}
          hideColaborators={true}
          opened={openedModalProsapesos}
          onClose={closeModalProsapesos}
          onReject={closeModalProsapesos}
          data={{
            rowSelection: [state.rowSelected]
          }}
          onAccept={onAccept}
        />
      )}
      <Grid>
        <div className='bg-bg h-full pb-4'>
          {state.loading ? (
            <Loading containerClassName="h-full" />
          ) : (
            <>
              <div className='bg-white px-6 lg:px-8 py-6 shadow-sm flex flex-wrap items-center justify-center md:justify-between gap-4'>
                <h1 className='flex-1 text-primary font-startup-semibold text-3xl text-center md:text-left'>Reporte de prosapesos</h1>
              </div>
              <div className='px-6 lg:px-8 container_table mt-6'>
                <DataTable
                  withTableBorder
                  withColumnBorders
                  borderRadius="md"
                  striped
                  highlightOnHover
                  horizontalSpacing="xs"
                  verticalSpacing="xs"
                  // height={window.innerHeight - 200}
                  sortStatus={state.sortStatus}
                  onSortStatusChange={onSortStatusChange}
                  recordsPerPageOptions={[10, 15, 20, 50, 100]}
                  recordsPerPageLabel={"Resultados por página"}
                  totalRecords={state.totalRecords || 0}
                  recordsPerPage={state.page_size}
                  page={state.page}
                  onPageChange={page => setState({ page })}
                  onRecordsPerPageChange={page => setState({ page_size: page })}
                  noRecordsText="No se encontraron resultados"
                  records={dataFilter}
                  columns={columns}
                />
              </div>
            </>
          )}
        </div>
      </Grid>
    </>
  );
};

export default ReporteProsapesos;