import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import sortBy from 'lodash/sortBy';
import { DataTable } from 'mantine-datatable';
import { Box, Button, Group } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { ReactComponent as AddIcon } from '../../../assets/icons/add-icon.svg';
import { ReactComponent as VerIcon } from '../../../assets/icons/accion01-ver.svg';
import { ReactComponent as EditarIcon } from '../../../assets/icons/accion02-editar.svg';
import { ReactComponent as EliminarIcon } from '../../../assets/icons/accion03-eliminar.svg';
import SEO from '../../../components/SEO';
import { ButtonCustom } from '../../../components/Button';
import Loading from '../../../components/Loading';
import EliminarModal from '../../../components/Modal/EliminarModal';
import { useCustomState } from '../../../hooks/useCustomState';
import Grid from '../components/Grid';
import { deletePermiso, getPermisos } from '../../../api/admin/permisos';
import pathsRouter from '../../../router/pathsRouter';
import { hasPermissions } from '../../../router/RequireAuth';
const prefix = "permisos";

const Permisos = () => {
  const [openedModalEliminar, { close: closeModalEliminar, open: openModalEliminar }] = useDisclosure(false);
  const navigate = useNavigate();
  const dataSelectedModal = useRef(null);
  const user_store = useSelector(state => state?.admin?.auth?.user?.user || state?.admin?.auth?.user);
  const [state, setState] = useCustomState({
    sending: false,
    loading: true,
    sortStatus: {},
    rowSelection: [],
    permisos: [],
  });

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  const loadData = useCallback(async () => {
    const permisos = await getPermisos();

    setState({
      permisos: permisos?.data,
      loading: false,
    });
  }, [setState]);

  const onActionTable = useCallback(async (params, action) => {
    if (action === "ver") {
      if (params?.id) navigate(`${pathsRouter.pathAdmin}/${prefix}/${params?.id}`);
    } else if (action === "editar") {
      if (params?.id) navigate(`${pathsRouter.pathAdmin}/${prefix}/editar-permiso/${params?.id}`);
    } else if (action === "eliminar") {
      dataSelectedModal.current = params;
      openModalEliminar();
    }
  }, [navigate, openModalEliminar]);

  const onSortStatusChange = useCallback(async (sortStatus) => {
    const data = sortBy(state.permisos, sortStatus.columnAccessor);

    setState({
      sortStatus: sortStatus,
      permisos: sortStatus.direction === 'desc' ? data.reverse() : data
    });
  }, [state.permisos, setState]);

  const columns = useMemo(
    () => [
      {
        accessor: 'name',
        title: "Nombre del permiso",
        sortable: true,
      },
      {
        accessor: 'actions',
        title: <Box mr={6}>Acciones</Box>,
        textAlign: 'center',
        width: 300,
        render: (data) => (
          <Group gap={1} justify="center" wrap="nowrap">
            {hasPermissions({ permissions: user_store?.Permission?.permissionsPermissions, requiredPermissions: ['leer'] }) && (
              <Button
                size="sm"
                variant="transparent"
                color="#101010"
                leftSection={<VerIcon height={12} width={15} />}
                classNames={{ root: "px-3 border hover:border-gray-200 transition-all" }}
                onClick={() => onActionTable(data, "ver")}
              >
                Ver
              </Button>
            )}
            {hasPermissions({ permissions: user_store?.Permission?.permissionsPermissions, requiredPermissions: ['actualizar'] }) && (
              <Button
                size="sm"
                variant="transparent"
                color="#101010"
                leftSection={<EditarIcon height={12} width={12} />}
                classNames={{ root: "px-3 border hover:border-gray-200 transition-all" }}
                onClick={() => onActionTable(data, "editar")}
              >
                Editar
              </Button>
            )}
            {hasPermissions({ permissions: user_store?.Permission?.permissionsPermissions, requiredPermissions: ['eliminar'] }) && (
              <Button
                size="sm"
                variant="transparent"
                color="#101010"
                leftSection={<EliminarIcon height={12} width={12} />}
                classNames={{ root: "px-3 border hover:border-gray-200 transition-all" }}
                onClick={() => onActionTable(data, "eliminar")}
              >
                Eliminar
              </Button>
            )}
          </Group>
        ),
      },
    ],
    [user_store?.Permission?.permissionsPermissions, onActionTable],
  );

  return (
    <>
      <SEO
        title="Permisos"
      />
      {hasPermissions({ permissions: user_store?.Permission?.permissionsPermissions, requiredPermissions: ['eliminar'] }) && (
        <EliminarModal
          title={"¿Estás seguro que deseas realizar esta acción?"}
          description={'Esta acción no se puede deshacer.'}
          acceptLabel={"Sí, eliminar"}
          opened={openedModalEliminar}
          onClose={closeModalEliminar}
          onReject={closeModalEliminar}
          onAccept={async () => {
            const params = dataSelectedModal.current;
            if (params?.id) await deletePermiso(params?.id);
            closeModalEliminar();
            loadData();
          }}
        />
      )}
      <Grid>
        <div className='bg-bg h-full pb-4'>
          {state.loading ? (
            <Loading containerClassName="h-full" />
          ) : (
            <>
              <div className='bg-white px-6 lg:px-8 py-6 shadow-sm flex flex-wrap items-center justify-center md:justify-between gap-4'>
                <h1 className='flex-1 text-primary font-startup-semibold text-3xl text-center md:text-left'>Permisos</h1>
                <div className='inline-flex flex-col sm:flex-row flex-wrap justify-center lg:justify-start gap-3'>
                  {hasPermissions({ permissions: user_store?.Permission?.permissionsPermissions, requiredPermissions: ['crear'] }) && (
                    <ButtonCustom
                      text="Nuevo Permiso"
                      classNameButton="!font-startup border-primary bg-primary hover:!bg-primaryHover hover:!border-primaryHover"
                      iconLeft={
                        <AddIcon height={14} width={14} />
                      }
                      onClick={() => navigate(`${pathsRouter.pathAdmin}/${prefix}/crear-permiso`)}
                    />
                  )}
                </div>
              </div>
              <div className='px-6 lg:px-8 container_table mt-6'>
                <DataTable
                  withTableBorder
                  withColumnBorders
                  borderRadius="md"
                  striped
                  highlightOnHover
                  horizontalSpacing="xs"
                  verticalSpacing="xs"
                  sortStatus={state.sortStatus}
                  onSortStatusChange={onSortStatusChange}
                  noRecordsText="No se encontraron resultados"
                  records={state.permisos}
                  columns={columns}
                />
              </div>
            </>
          )}
        </div>
      </Grid>
    </>
  );
};

export default Permisos;