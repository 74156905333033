import qs from 'qs';
import axios from "..";
const prefix = "admin/acciones";

export const getAcciones = async (id, filters) => {
  try {
    const params = qs.stringify({
      populate: ["departamentos", "tipos"],
      id: id,
      filters: filters,
    });
    const response = await axios.get(`/${prefix}?${params}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createAccion = async (params) => {
  try {
    const response = await axios.post(`/${prefix}/nueva-accion`, params, {
      maxBodyLength: Infinity,
      headers: { "Content-Type": "multipart/form-data" }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateAccion = async (id, params) => {
  try {
    const response = await axios.put(`/${prefix}/${id}`, params, {
      maxBodyLength: Infinity,
      headers: { "Content-Type": "multipart/form-data" }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteAccion = async (id) => {
  try {
    const response = await axios.delete(`/${prefix}/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};