import React from 'react';
import { Link } from 'react-router-dom';
import SEO from '../../components/SEO';
import pathsRouter from '../../router/pathsRouter';

import { Container, Icon, Title, Message } from './styles';

function Unathorized() {
  const canGoBack = window.history.state && window.history.state.idx > 0;
  const isAdmin = (document.location?.pathname)?.includes(`${pathsRouter.pathAdmin}/`);

  return (
    <>
      <SEO title="Acceso denegado" />
      <div style={Container}>
        <Icon />
        <h1 style={Title}>¡Acceso no autorizado!</h1>
        <p style={Message}>
          Lo sentimos, no tienes los permisos necesarios para acceder a este contenido.
        </p>
        <Link
          to={canGoBack ? -1 : (isAdmin ? `${pathsRouter.pathAdmin}/` : "/")}
          className='font-startup-medium text-sm py-2 px-5 bg-primary hover:!bg-primaryHover border !border-primary text-white rounded-lg transition-all'
        >
          {canGoBack ? "Regresar" : "Ir a la página principal"}
        </Link>
      </div>
    </>
  );
}
export default Unathorized;