import qs from 'qs';
import axios from "..";
const prefix = "admin/departamentos";

export const getDepartamentos = async (id, filters) => {
  try {
    const params = qs.stringify({
      id: id,
      filters: filters,
    });
    const response = await axios.get(`/${prefix}?${params}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createDepartamento = async (params) => {
  try {
    const _params = qs.stringify(params, { arrayFormat: 'brackets' });

    const response = await axios.post(`/${prefix}/nuevo-departamento`, _params);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateDepartamento = async (id, params) => {
  try {
    const _params = qs.stringify(params, { arrayFormat: 'brackets' });

    const response = await axios.put(`/${prefix}/${id}`, _params);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteDepartamento = async (id) => {
  try {
    const response = await axios.delete(`/${prefix}/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};