import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import es from 'dayjs/locale/es';
const default_timezone = 'America/Mexico_City';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale(es);
dayjs.tz.setDefault(default_timezone);

export function dayjs_custom(date, { tz, dayjs: _dayjs } = {}) {
  try {
    const _tz = tz || default_timezone;
    return (_dayjs || dayjs).tz(date, _tz); // locale(es);
  } catch (error) {
    return null;
  }
}

export default dayjs.tz;
export { dayjs };